// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../api/Api.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as Button from "../../components/Button/Button.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as CheckOutForm from "./form/CheckOutForm.res.js";
import * as CheckOutScss from "./CheckOut.scss";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProductReservationData from "../../../bundles/product-reservation/form/ProductReservationData.res.js";

var css = CheckOutScss;

var initialState_input = ProductReservationData.Input.empty();

var initialState = {
  status: "Editing",
  input: initialState_input,
  results: undefined,
  submissionError: false
};

function CheckOut(props) {
  var setCartQty = props.setCartQty;
  var openCheckOutSuccess = props.openCheckOutSuccess;
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "Validate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            input: state.input,
                            results: ProductReservationData.Validate.all(state.input),
                            submissionError: state.submissionError
                          }
                        };
              case "Submit" :
                  var match = state.status;
                  if (match === "Editing") {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: state.status,
                              input: state.input,
                              results: ProductReservationData.Validate.all(state.input),
                              submissionError: state.submissionError
                            },
                            _1: (function (param) {
                                if (ProductReservationData.Validate.valid(Belt_Option.getExn(param.state.results))) {
                                  return param.dispatch("CheckOutModalCart");
                                }
                                
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "CheckOutModalCart" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "Submitting",
                            input: state.input,
                            results: state.results,
                            submissionError: state.submissionError
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api.checkOutModalCart(param.state.input), (function (x) {
                                      if (x.TAG !== "Ok") {
                                        return dispatch("FailSubmission");
                                      }
                                      setCartQty(0);
                                      openCheckOutSuccess();
                                    }));
                            })
                        };
              case "FailSubmission" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "Editing",
                            input: state.input,
                            results: state.results,
                            submissionError: true
                          }
                        };
              
            }
          } else {
            var input = action._0;
            var match$1 = state.status;
            if (match$1 !== "Editing") {
              return "NoUpdate";
            }
            var match$2 = state.results;
            if (match$2 !== undefined) {
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: {
                        status: state.status,
                        input: input,
                        results: state.results,
                        submissionError: state.submissionError
                      },
                      _1: (function (param) {
                          param.dispatch("Validate");
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        status: state.status,
                        input: input,
                        results: state.results,
                        submissionError: state.submissionError
                      }
                    };
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.status;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: "Check Out",
                      className: css.title
                    }),
                JsxRuntime.jsx("div", {
                      children: "Complete the form below to let us know how to contact you and take your product live.",
                      className: css.content
                    }),
                JsxRuntime.jsx(CheckOutForm.make, {
                      input: state.input,
                      results: state.results,
                      update: (function (input) {
                          dispatch({
                                TAG: "Update",
                                _0: input
                              });
                        }),
                      submit: (function (param) {
                          dispatch("Submit");
                        }),
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Button.make, {
                                  size: "MD",
                                  color: "Primary",
                                  expanded: false,
                                  busy: match$1 !== "Editing",
                                  submit: true,
                                  className: css.buttonReserve,
                                  children: "Confirm"
                                }),
                            className: css.cartButton
                          })
                    })
              ]
            });
}

var Input;

var Validate;

var ValidationResult;

var make = CheckOut;

export {
  css ,
  Input ,
  Validate ,
  ValidationResult ,
  initialState ,
  make ,
}
/* css Not a pure module */
