// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Events from "../../../libs/Events.res.js";

function useScrollTransition(isEnabled) {
  var initState = isEnabled ? 0.0 : 1.0;
  var match = React.useState(function () {
        return initState;
      });
  var setAlpha = match[1];
  var alpha = match[0];
  var stateRef = React.useRef(initState);
  stateRef.current = alpha;
  React.useEffect((function () {
          if (isEnabled) {
            return Events.Subscriptions.subscribeToScroll(function (param) {
                        var windowScrollY = window.scrollY;
                        if (windowScrollY < 100.0 || stateRef.current < 1.0) {
                          return setAlpha(function (param) {
                                      return windowScrollY / 100.0;
                                    });
                        }
                        
                      });
          }
          
        }), []);
  return Math.min(alpha, 1.0);
}

export {
  useScrollTransition ,
}
/* react Not a pure module */
