// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconChevronDown(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "ChevronDown";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("polygon", {
                    clipRule: "evenodd",
                    fill: Icon.mapColor(props.color),
                    fillRule: "evenodd",
                    points: "2 4 8 10 14 4 15 5 8 12 1 5"
                  })
            });
}

var make = IconChevronDown;

export {
  make ,
}
/* Icon Not a pure module */
