// Generated by ReScript, PLEASE EDIT WITH CARE


var BareMetal = {
  index: "/services/bare_metal_iaas",
  zenlayer: "/services/bare_metal_iaas/zenlayer",
  enzu: "/services/bare_metal_iaas/enzu",
  hivelocity: "/services/bare_metal_iaas/hivelocity",
  evocative: "/services/bare_metal_iaas/evocative"
};

var Colocation = {
  index: "/services/colocation"
};

var Multicloud = {
  index: "/services/multicloud"
};

export {
  BareMetal ,
  Colocation ,
  Multicloud ,
}
/* No side effect */
