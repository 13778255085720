// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../Link/A.res.js";
import * as P from "../../../../Paragraph/P.res.js";
import * as IconEdit from "../../../../../icons/IconEdit.res.js";
import * as IconFile from "../../../../../icons/IconFile.res.js";
import * as IconCloud from "../../../../../icons/IconCloud.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconMapView from "../../../../../icons/IconMapView.res.js";
import * as IconBuilding from "../../../../../icons/IconBuilding.res.js";
import * as IconListView from "../../../../../icons/IconListView.res.js";
import * as IconProducts from "../../../../../icons/IconProducts.res.js";
import * as IconColocation from "../../../../../icons/IconColocation.res.js";
import * as Routes_Project from "../../../../../../routes/common/Routes_Project.res.js";
import * as IconChevronDown from "../../../../../icons/IconChevronDown.res.js";
import * as NavPopoverPanel from "../../../PopoverPanel/NavPopoverPanel.res.js";
import * as Routes_BlogPost from "../../../../../../routes/common/Routes_BlogPost.res.js";
import * as Routes_Location from "../../../../../../routes/common/Routes_Location.res.js";
import * as Routes_Provider from "../../../../../../routes/common/Routes_Provider.res.js";
import * as Routes_Resource from "../../../../../../routes/common/Routes_Resource.res.js";
import * as Routes_Services from "../../../../../../routes/common/Routes_Services.res.js";
import * as React from "@headlessui/react";
import * as JsxRuntime from "react/jsx-runtime";
import * as NavPopoverPanelMain from "../../../PopoverPanel/NavPopoverPanelMain/NavPopoverPanelMain.res.js";
import * as NavigationCountries from "../../../shared/NavigationCountries.res.js";
import * as DesktopNavigationCss from "../../DesktopNavigationCss.res.js";
import * as NavPopoverPanelAside from "../../../PopoverPanel/NavPopoverPanelAside/NavPopoverPanelAside.res.js";
import * as NavPopoverPanelFooter from "../../../PopoverPanel/NavPopoverPanelFooter/NavPopoverPanelFooter.res.js";
import * as IconDatacenterProviders from "../../../../../icons/IconDatacenterProviders.res.js";
import * as Routes_LocationBrochure from "../../../../../../routes/common/Routes_LocationBrochure.res.js";
import * as Routes_ProviderBrochure from "../../../../../../routes/common/Routes_ProviderBrochure.res.js";
import * as IconDatacenterColocation from "../../../../../icons/IconDatacenterColocation.res.js";

function DesktopNavigationMenus$BareMetal(props) {
  return JsxRuntime.jsxs(React.Popover, {
              className: DesktopNavigationCss.popover,
              children: [
                JsxRuntime.jsxs(React.PopoverButton, {
                      className: DesktopNavigationCss.popoverButton,
                      children: [
                        "Bare Metal",
                        JsxRuntime.jsx(IconChevronDown.make, {
                              size: "XS",
                              color: "White",
                              className: DesktopNavigationCss.groupOpen
                            })
                      ]
                    }),
                JsxRuntime.jsx(NavPopoverPanel.make, {
                      cols: 2,
                      rows: 1,
                      className: DesktopNavigationCss.popoverPanel,
                      children: JsxRuntime.jsxs(NavPopoverPanelMain.make, {
                            children: [
                              JsxRuntime.jsx(NavPopoverPanelMain.Item.make, {
                                    title: "Bare Metal",
                                    description: "Secure services from the top list of top bare metal providers.",
                                    icon: JsxRuntime.jsx(IconColocation.make, {
                                          size: "SM",
                                          color: "Teal"
                                        }),
                                    children: Caml_option.some(JsxRuntime.jsxs("ul", {
                                              children: [
                                                JsxRuntime.jsx("li", {
                                                      children: JsxRuntime.jsx(A.make, {
                                                            href: Routes_Services.BareMetal.index,
                                                            className: DesktopNavigationCss.linkContainer,
                                                            children: "Bare Metal Services"
                                                          })
                                                    }),
                                                JsxRuntime.jsx("li", {
                                                      children: JsxRuntime.jsx(A.make, {
                                                            href: Routes_Services.BareMetal.enzu,
                                                            className: DesktopNavigationCss.linkContainer,
                                                            children: "Enzu Bare Metal"
                                                          })
                                                    }),
                                                JsxRuntime.jsx("li", {
                                                      children: JsxRuntime.jsx(A.make, {
                                                            href: Routes_Services.BareMetal.zenlayer,
                                                            className: DesktopNavigationCss.linkContainer,
                                                            children: "Zenlayer Bare Metal"
                                                          })
                                                    }),
                                                JsxRuntime.jsx("li", {
                                                      children: JsxRuntime.jsx(A.make, {
                                                            href: Routes_Services.BareMetal.hivelocity,
                                                            className: DesktopNavigationCss.linkContainer,
                                                            children: "Hivelocity Bare Metal"
                                                          })
                                                    }),
                                                JsxRuntime.jsx("li", {
                                                      children: JsxRuntime.jsx(A.make, {
                                                            href: Routes_Services.BareMetal.evocative,
                                                            className: DesktopNavigationCss.linkContainer,
                                                            children: "Evocative Bare Metal"
                                                          })
                                                    })
                                              ],
                                              className: DesktopNavigationCss.listContainer
                                            }))
                                  }),
                              JsxRuntime.jsx(NavPopoverPanelMain.Item.make, {
                                    title: "Bare Metal Project",
                                    description: "Get proposals directly from multiple bare metal providers.",
                                    icon: JsxRuntime.jsx(IconFile.make, {
                                          size: "SM",
                                          color: "Teal"
                                        }),
                                    href: Routes_Project.BareMetal.$$new
                                  })
                            ]
                          })
                    })
              ]
            });
}

var BareMetal = {
  make: DesktopNavigationMenus$BareMetal
};

function DesktopNavigationMenus$Colocation(props) {
  return JsxRuntime.jsxs(React.Popover, {
              className: DesktopNavigationCss.popover,
              children: [
                JsxRuntime.jsxs(React.PopoverButton, {
                      className: DesktopNavigationCss.popoverButton,
                      children: [
                        "Colocation",
                        JsxRuntime.jsx(IconChevronDown.make, {
                              size: "XS",
                              color: "White",
                              className: DesktopNavigationCss.groupOpen
                            })
                      ]
                    }),
                JsxRuntime.jsxs(NavPopoverPanel.make, {
                      cols: 3,
                      rows: 1,
                      className: DesktopNavigationCss.popoverPanel,
                      children: [
                        JsxRuntime.jsxs(NavPopoverPanelMain.make, {
                              children: [
                                JsxRuntime.jsx(NavPopoverPanelMain.Item.make, {
                                      title: "Data Center Locations",
                                      description: "Select the country or view all locations by clicking on a link below.",
                                      icon: JsxRuntime.jsx(IconMapView.make, {
                                            size: "SM",
                                            color: "Teal"
                                          }),
                                      children: Caml_option.some(NavigationCountries.countries.length !== 0 ? JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx("div", {
                                                          children: Belt_Array.mapWithIndex(NavigationCountries.countries, (function (index, country) {
                                                                  return JsxRuntime.jsx("div", {
                                                                              children: JsxRuntime.jsx(A.make, {
                                                                                    href: country.href,
                                                                                    className: DesktopNavigationCss.countryText,
                                                                                    children: country.name
                                                                                  })
                                                                            }, String(index));
                                                                })),
                                                          className: DesktopNavigationCss.countriesList
                                                        }),
                                                    JsxRuntime.jsx("div", {
                                                          children: JsxRuntime.jsx(A.make, {
                                                                href: Routes_Location.index,
                                                                className: DesktopNavigationCss.viewAllContainer,
                                                                children: "View All Data Centers"
                                                              })
                                                        })
                                                  ],
                                                  className: DesktopNavigationCss.sectioncCountriesList
                                                }) : JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(P.make, {
                                                        children: "No Data Centers Found"
                                                      }),
                                                  className: DesktopNavigationCss.noDataContainer
                                                }))
                                    }),
                                JsxRuntime.jsx(NavPopoverPanelFooter.make, {
                                      title: "Colocation Project",
                                      description: "Get pricing directly from multiple providers by submitting your colocation project.",
                                      callToAction: "Start Project",
                                      href: Routes_Project.Colocation.$$new
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(NavPopoverPanelAside.make, {
                              title: "More",
                              children: [
                                JsxRuntime.jsx(NavPopoverPanelAside.Item.make, {
                                      title: "Data Center Directory",
                                      icon: JsxRuntime.jsx(IconProducts.make, {
                                            size: "SM",
                                            color: "Gray"
                                          }),
                                      href: Routes_Location.index
                                    }),
                                JsxRuntime.jsx(NavPopoverPanelAside.Item.make, {
                                      title: "Colocation Overview",
                                      icon: JsxRuntime.jsx(IconListView.make, {
                                            size: "SM",
                                            color: "Gray"
                                          }),
                                      href: Routes_Services.Colocation.index
                                    }),
                                JsxRuntime.jsx(NavPopoverPanelAside.Item.make, {
                                      title: "Data Center Brochures",
                                      icon: JsxRuntime.jsx(IconFile.make, {
                                            size: "SM",
                                            color: "Gray"
                                          }),
                                      href: Routes_LocationBrochure.index
                                    }),
                                JsxRuntime.jsx(NavPopoverPanelAside.Item.make, {
                                      title: "Colocation Providers",
                                      icon: JsxRuntime.jsx(IconDatacenterColocation.make, {
                                            size: "SM",
                                            color: "Gray"
                                          }),
                                      href: Routes_Provider.colocation
                                    }),
                                JsxRuntime.jsx(NavPopoverPanelAside.Item.make, {
                                      title: "All Services Providers",
                                      icon: JsxRuntime.jsx(IconDatacenterProviders.make, {
                                            size: "SM",
                                            color: "Gray"
                                          }),
                                      href: Routes_Provider.index
                                    })
                              ]
                            })
                      ]
                    })
              ]
            });
}

var Colocation = {
  make: DesktopNavigationMenus$Colocation
};

function DesktopNavigationMenus$Cloud(props) {
  return JsxRuntime.jsxs(React.Popover, {
              className: DesktopNavigationCss.popover,
              children: [
                JsxRuntime.jsxs(React.PopoverButton, {
                      className: DesktopNavigationCss.popoverButton,
                      children: [
                        "Cloud",
                        JsxRuntime.jsx(IconChevronDown.make, {
                              size: "XS",
                              color: "White",
                              className: DesktopNavigationCss.groupOpen
                            })
                      ]
                    }),
                JsxRuntime.jsx(NavPopoverPanel.make, {
                      cols: 2,
                      rows: 1,
                      className: DesktopNavigationCss.popoverPanel,
                      children: JsxRuntime.jsxs(NavPopoverPanelMain.make, {
                            children: [
                              JsxRuntime.jsx(NavPopoverPanelMain.Item.make, {
                                    title: "Multicloud",
                                    description: "Multicloud Deployment to Any Cloud, Anywhere, Anytime.",
                                    icon: JsxRuntime.jsx(IconCloud.make, {
                                          size: "MD",
                                          color: "Teal",
                                          className: "w-8"
                                        }),
                                    href: Routes_Services.Multicloud.index
                                  }),
                              JsxRuntime.jsx(NavPopoverPanelFooter.make, {
                                    title: "Cloud Project",
                                    description: "Submit an application and get proposals from multiple providers to compare prices.",
                                    callToAction: "Start Project",
                                    href: Routes_Project.Cloud.$$new
                                  })
                            ]
                          })
                    })
              ]
            });
}

var Cloud = {
  make: DesktopNavigationMenus$Cloud
};

function DesktopNavigationMenus$Knowledge(props) {
  return JsxRuntime.jsxs(React.Popover, {
              className: DesktopNavigationCss.popover,
              children: [
                JsxRuntime.jsxs(React.PopoverButton, {
                      className: DesktopNavigationCss.popoverButton,
                      children: [
                        "Knowledge",
                        JsxRuntime.jsx(IconChevronDown.make, {
                              size: "XS",
                              color: "White",
                              className: DesktopNavigationCss.groupOpen
                            })
                      ]
                    }),
                JsxRuntime.jsx(NavPopoverPanel.make, {
                      cols: 2,
                      rows: 1,
                      className: DesktopNavigationCss.popoverPanel,
                      children: JsxRuntime.jsxs(NavPopoverPanelMain.make, {
                            children: [
                              JsxRuntime.jsx(NavPopoverPanelMain.Item.make, {
                                    title: "Resources",
                                    description: "A collection of downloadable data centers resources.",
                                    icon: JsxRuntime.jsx(IconFile.make, {
                                          size: "SM",
                                          color: "Teal"
                                        }),
                                    children: Caml_option.some(JsxRuntime.jsx("ul", {
                                              children: Belt_Array.map(props.resources, (function (resource) {
                                                      var slug = resource.slug;
                                                      return JsxRuntime.jsx("li", {
                                                                  children: slug === "all-resources" ? JsxRuntime.jsx(A.make, {
                                                                          href: Routes_Resource.resourcesIndex,
                                                                          className: DesktopNavigationCss.linkContainer,
                                                                          children: resource.navigationName
                                                                        }) : JsxRuntime.jsx(A.make, {
                                                                          href: Routes_Resource.category(resource.slug),
                                                                          className: DesktopNavigationCss.linkContainer,
                                                                          children: resource.navigationName
                                                                        })
                                                                }, resource.slug);
                                                    })),
                                              className: DesktopNavigationCss.listContainer
                                            }))
                                  }),
                              JsxRuntime.jsx(NavPopoverPanelMain.Item.make, {
                                    title: "Blog",
                                    description: "Data Center News and Trending Topics",
                                    icon: JsxRuntime.jsx(IconEdit.make, {
                                          size: "SM",
                                          color: "Teal"
                                        }),
                                    href: Routes_BlogPost.news
                                  })
                            ]
                          })
                    })
              ]
            });
}

var Knowledge = {
  make: DesktopNavigationMenus$Knowledge
};

function DesktopNavigationMenus$Providers(props) {
  return JsxRuntime.jsxs(React.Popover, {
              className: DesktopNavigationCss.popover,
              children: [
                JsxRuntime.jsxs(React.PopoverButton, {
                      className: DesktopNavigationCss.popoverButton,
                      children: [
                        "Providers",
                        JsxRuntime.jsx(IconChevronDown.make, {
                              size: "XS",
                              color: "White",
                              className: DesktopNavigationCss.groupOpen
                            })
                      ]
                    }),
                JsxRuntime.jsx(NavPopoverPanel.make, {
                      cols: 2,
                      rows: 1,
                      className: DesktopNavigationCss.popoverPanel,
                      children: JsxRuntime.jsxs(NavPopoverPanelMain.make, {
                            children: [
                              JsxRuntime.jsx(NavPopoverPanelMain.Item.make, {
                                    title: "Providers",
                                    description: "A curated list of providers from all over the world.",
                                    icon: JsxRuntime.jsx(IconBuilding.make, {
                                          size: "SM",
                                          color: "Teal"
                                        }),
                                    children: Caml_option.some(JsxRuntime.jsxs("ul", {
                                              children: [
                                                JsxRuntime.jsx("li", {
                                                      children: JsxRuntime.jsx(A.make, {
                                                            href: Routes_Provider.index,
                                                            className: DesktopNavigationCss.linkContainer,
                                                            children: "All providers"
                                                          })
                                                    }),
                                                JsxRuntime.jsx("li", {
                                                      children: JsxRuntime.jsx(A.make, {
                                                            href: Routes_Provider.colocation,
                                                            className: DesktopNavigationCss.linkContainer,
                                                            children: "Colocation Providers"
                                                          })
                                                    }),
                                                JsxRuntime.jsx("li", {
                                                      children: JsxRuntime.jsx(A.make, {
                                                            href: Routes_Provider.bareMetal,
                                                            className: DesktopNavigationCss.linkContainer,
                                                            children: "Bare Metal Providers"
                                                          })
                                                    }),
                                                JsxRuntime.jsx("li", {
                                                      children: JsxRuntime.jsx(A.make, {
                                                            href: Routes_Provider.cloud,
                                                            className: DesktopNavigationCss.linkContainer,
                                                            children: "Cloud Providers"
                                                          })
                                                    })
                                              ],
                                              className: DesktopNavigationCss.listContainer
                                            }))
                                  }),
                              JsxRuntime.jsx(NavPopoverPanelMain.Item.make, {
                                    title: "Provider Brochures",
                                    description: "Brochures with helpful information about providers.",
                                    icon: JsxRuntime.jsx(IconFile.make, {
                                          size: "SM",
                                          color: "Teal"
                                        }),
                                    href: Routes_ProviderBrochure.index
                                  })
                            ]
                          })
                    })
              ]
            });
}

var Providers = {
  make: DesktopNavigationMenus$Providers
};

var Css;

export {
  Css ,
  BareMetal ,
  Colocation ,
  Cloud ,
  Knowledge ,
  Providers ,
}
/* A Not a pure module */
