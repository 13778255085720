// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../Link/A.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Money from "../../../libs/Money.res.js";
import * as React from "react";
import * as Button from "../Button/Button.res.js";
import * as CartItem from "../../../bundles/product-detail/cart-modal/CartItem.res.js";
import * as IconCart from "../../icons/IconCart.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as WithDataProps from "../WithDataProps/WithDataProps.res.js";
import * as CartListScss from "./CartList.scss";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_Marketplace from "../../../routes/common/Routes_Marketplace.res.js";

var css = CartListScss;

function CartList(props) {
  var onCartListLoad = props.onCartListLoad;
  var userLoginStatus = props.userLoginStatus;
  var updateItemToCart = props.updateItemToCart;
  var openCheckOutPage = props.openCheckOutPage;
  var items = props.items;
  var totalAmount = Belt_Array.reduce(items, 0.0, (function (total, item) {
          return total + item.pricePerMonth * item.qty;
        }));
  var hasExpiredProducts = Belt_Array.reduce(items, 0, (function (totalExpired, item) {
          if (item.isExpired) {
            return totalExpired + 1 | 0;
          } else {
            return totalExpired;
          }
        }));
  React.useEffect((function () {
          onCartListLoad();
        }), []);
  var tmp;
  if (items.length !== 0) {
    var tmp$1;
    tmp$1 = userLoginStatus === "LoggedIn" ? JsxRuntime.jsx(WithDataProps.make, {
            props: {
              "data-test-id": "cart-list-total-price"
            },
            children: JsxRuntime.jsx("div", {
                  children: "Total: " + (Money.formatWithCents(totalAmount) + "/month"),
                  className: css.totalPrice
                })
          }) : null;
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            Belt_Array.map(items, (function (item) {
                    return JsxRuntime.jsx(CartItem.make, {
                                providerName: item.providerName,
                                name: item.name,
                                image: item.providerLogo,
                                category: item.category,
                                price: item.pricePerMonth,
                                qty: item.qty,
                                updateQty: (function (qty) {
                                    updateItemToCart(item.id, qty);
                                  }),
                                saleEnd: item.endDate,
                                isExpired: item.isExpired,
                                userLoginStatus: userLoginStatus
                              }, ID.toString(item.id));
                  })),
            hasExpiredProducts > 0 ? JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("div", {
                          children: "Please, remove an expired product to proceed."
                        }),
                    className: css.expiredNotification
                  }) : null,
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsx(Button.make, {
                          size: "MD",
                          color: "Primary",
                          expanded: false,
                          visuallyDisabled: hasExpiredProducts > 0,
                          submit: false,
                          className: css.buttonReserve,
                          onClick: (function (param) {
                              if (hasExpiredProducts > 0) {
                                return ;
                              } else {
                                return openCheckOutPage();
                              }
                            }),
                          children: "Confirm"
                        }),
                    tmp$1
                  ],
                  className: css.cartButton
                }),
            JsxRuntime.jsx("div", {
                  children: "No up front payment required. You will be contacted within 24 business hours about your request.",
                  className: css.cartNote
                })
          ]
        });
  } else {
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsx(IconCart.make, {
                        size: "XXXXL",
                        color: "Teal"
                      }),
                  className: css.cartIcon
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsx("span", {
                          children: "Your cart is empty. Go to the "
                        }),
                    JsxRuntime.jsx(A.make, {
                          href: Routes_Marketplace.colocation,
                          className: css.link,
                          children: "marketplace"
                        }),
                    JsxRuntime.jsx("span", {
                          children: " and add products to the card."
                        })
                  ],
                  className: css.emptyCartContent
                })
          ]
        });
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: "Shopping Cart",
                      className: css.title
                    }),
                JsxRuntime.jsx("div", {
                      children: "No up front payment required. You will be contacted within 24 business hours about your request.",
                      className: css.content
                    }),
                JsxRuntime.jsx(JsxRuntime.Fragment, {
                      children: Caml_option.some(tmp)
                    })
              ]
            });
}

var make = CartList;

export {
  css ,
  make ,
}
/* css Not a pure module */
