// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var popoverPanelMainTitle = Cx.cx([
      "text-black",
      "font-semibold",
      "text-base"
    ]);

var itemWrapper = Cx.cx([
      "grid",
      "p-7",
      "bg-white",
      "rounded"
    ]);

var itemContent = Cx.cx([
      "pl-16",
      "mt-5"
    ]);

var sectionContainer = Cx.cx([
      "col-span-2",
      "flex",
      "flex-row",
      "gap-4"
    ]);

var innerContainer = Cx.cx([
      "flex",
      "flex-col",
      "gap-4",
      "w-full"
    ]);

var childrenContainer = Cx.cx(["col-span-2"]);

var iconContainer = Cx.cx([
      "flex",
      "flex-row",
      "gap-4",
      "items-start"
    ]);

var icon = Cx.cx([
      "bg-gray-50",
      "p-2.5",
      "rounded-lg",
      "size-12",
      "shrink-0",
      "flex",
      "justify-center",
      "items-center"
    ]);

var textContainer = Cx.cx([
      "flex",
      "flex-col"
    ]);

var titleText = Cx.cx([
      "text-black",
      "text-base",
      "font-semibold"
    ]);

var textDescription = Cx.cx([
      "text-gray",
      "text-sm"
    ]);

var contentMargin = Cx.cx(["mt-5"]);

var chevronContainer = Cx.cx([
      "flex",
      "justify-center",
      "items-center",
      "size-6",
      "my-auto",
      "ml-auto"
    ]);

export {
  popoverPanelMainTitle ,
  itemWrapper ,
  itemContent ,
  sectionContainer ,
  innerContainer ,
  childrenContainer ,
  iconContainer ,
  icon ,
  textContainer ,
  titleText ,
  textDescription ,
  contentMargin ,
  chevronContainer ,
}
/* popoverPanelMainTitle Not a pure module */
