// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../Link/A.res.js";
import * as Button from "../../Button/Button.res.js";
import * as Routes_User from "../../../../routes/common/Routes_User.res.js";
import * as NavSignedOutCss from "./NavSignedOutCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function NavSignedOut(props) {
  switch (props.device) {
    case "Desktop" :
    case "Tablet" :
        break;
    case "Mobile" :
        return JsxRuntime.jsxs("ul", {
                    children: [
                      JsxRuntime.jsx("li", {
                            children: JsxRuntime.jsx(Button.AsLink.make, {
                                  href: Routes_User.signIn,
                                  size: "MD",
                                  color: "Primary",
                                  ghost: true,
                                  expanded: true,
                                  children: "Sign In"
                                })
                          }),
                      JsxRuntime.jsx("li", {
                            children: JsxRuntime.jsx(Button.AsLink.make, {
                                  href: Routes_User.signUp,
                                  size: "MD",
                                  color: "Primary",
                                  expanded: true,
                                  className: NavSignedOutCss.signInbuttonMobile,
                                  children: "Sign Up"
                                })
                          })
                    ],
                    className: NavSignedOutCss.topLinks
                  });
    
  }
  return JsxRuntime.jsxs("ul", {
              children: [
                JsxRuntime.jsx("li", {
                      children: JsxRuntime.jsx(A.make, {
                            href: Routes_User.signIn,
                            className: NavSignedOutCss.signInbutton,
                            children: "Sign In"
                          }),
                      className: NavSignedOutCss.topLinksItem
                    }),
                JsxRuntime.jsx("li", {
                      children: JsxRuntime.jsx(A.make, {
                            href: Routes_User.signUp,
                            className: NavSignedOutCss.signUpbuttonDesktop,
                            children: "Sign Up"
                          }),
                      className: NavSignedOutCss.topLinksItem
                    })
              ],
              className: NavSignedOutCss.topLinks
            });
}

var Css;

var make = NavSignedOut;

export {
  Css ,
  make ,
}
/* A Not a pure module */
