// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../Link/A.res.js";
import * as Control from "../../Control/Control.res.js";
import * as IconCart from "../../../icons/IconCart.res.js";
import * as Routes_Page from "../../../../routes/common/Routes_Page.res.js";
import * as NavigationHooks from "../NavigationHooks.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Global from "Client/common/global";
import * as TopHeaderNavigationCss from "./TopHeaderNavigationCss.res.js";

function TopHeaderNavigation(props) {
  var toggleCart = props.toggleCart;
  var scrollAlphaChannel = NavigationHooks.useScrollTransition(true);
  var isVisible = scrollAlphaChannel < 1.0;
  if (!isVisible) {
    return null;
  }
  var tmp;
  tmp = props.userLoginStatus === "LoggedIn" ? JsxRuntime.jsxs(Control.make, {
          className: TopHeaderNavigationCss.cartWrapper,
          onClick: (function (param) {
              toggleCart();
            }),
          children: [
            JsxRuntime.jsx(IconCart.make, {
                  size: "MD",
                  color: "DarkGray"
                }),
            "Cart",
            JsxRuntime.jsx("span", {
                  children: String(props.totalCartQuantity),
                  className: TopHeaderNavigationCss.cartCounter
                })
          ]
        }) : null;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs(A.make, {
                      href: Routes_Page.contact,
                      className: TopHeaderNavigationCss.callAction,
                      children: [
                        "Get Colocation Pricing Now! ",
                        JsxRuntime.jsx("span", {
                              children: Global.DCContactNumber,
                              className: TopHeaderNavigationCss.numberText
                            })
                      ]
                    }),
                tmp
              ],
              className: TopHeaderNavigationCss.topHeaderWrapper
            });
}

var Css;

var make = TopHeaderNavigation;

export {
  Css ,
  make ,
}
/* A Not a pure module */
