// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var wrapper = Cx.cx([
      "flex",
      "flex-row",
      "gap-5",
      "items-center"
    ]);

var popover = Cx.cx([
      "relative",
      "group"
    ]);

var popoverButton = Cx.cx([
      "flex",
      "items-center",
      "gap-x-1",
      "text-sm",
      "font-semibold",
      "leading-6",
      "text-white",
      "hover:text-teal",
      "focus:outline-none"
    ]);

var popoverPanel = Cx.cx([
      "flex",
      "flex-col",
      "gap-1",
      "bg-white",
      "rounded-lg",
      "w-[300px]",
      "p-2.5",
      "[--anchor-gap:2rem]",
      "drop-shadow",
      "!z-1000"
    ]);

var groupOpenIcon = Cx.cx([
      "cursor-pointer",
      "transition-transform",
      "duration-200",
      "ease-in-out",
      "group-data-[open]:rotate-180",
      "group-data-[open]:text-teal-500"
    ]);

var groupLinkSubItem = Cx.cx([
      "text-black",
      "text-sm",
      "font-normal",
      "leading-5",
      "hover:text-teal"
    ]);

var groupLink = Cx.cx([
      "flex",
      "flex-col",
      "gap-1"
    ]);

var contactUsButton = Cx.cx([
      "text-white",
      "text-center",
      "py-2.5",
      "px-5",
      "flex-1",
      "text-sm",
      "border",
      "border-white",
      "hover:text-teal",
      "rounded",
      "font-semibold"
    ]);

export {
  wrapper ,
  popover ,
  popoverButton ,
  popoverPanel ,
  groupOpenIcon ,
  groupLinkSubItem ,
  groupLink ,
  contactUsButton ,
}
/* wrapper Not a pure module */
