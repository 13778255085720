// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconChevron(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Chat";
  var tmp;
  tmp = props.direction === "Left" ? JsxRuntime.jsx("path", {
          d: "M15.75 19.5L8.25 12l7.5-7.5",
          strokeLinecap: "round",
          strokeLinejoin: "round"
        }) : JsxRuntime.jsx("path", {
          d: "M8.25 4.5l7.5 7.5-7.5 7.5",
          strokeLinecap: "round",
          strokeLinejoin: "round"
        });
  return JsxRuntime.jsx("svg", {
              children: tmp,
              className: props.className,
              title: title,
              fill: "none",
              stroke: Icon.mapColor(props.color),
              strokeWidth: "1.5",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            });
}

var make = IconChevron;

export {
  make ,
}
/* Icon Not a pure module */
