// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Routes_Country from "../../../../routes/common/Routes_Country.res.js";

var countries = [
  {
    name: "United States",
    href: Routes_Country.unitedStates
  },
  {
    name: "China",
    href: Routes_Country.china
  },
  {
    name: "United Kingdom",
    href: Routes_Country.unitedKingdom
  },
  {
    name: "Germany",
    href: Routes_Country.germany
  },
  {
    name: "Canada",
    href: Routes_Country.canada
  },
  {
    name: "India",
    href: Routes_Country.india
  },
  {
    name: "Netherlands",
    href: Routes_Country.netherlands
  },
  {
    name: "France",
    href: Routes_Country.france
  },
  {
    name: "Australia",
    href: Routes_Country.australia
  },
  {
    name: "Japan",
    href: Routes_Country.japan
  },
  {
    name: "Brazil",
    href: Routes_Country.brazil
  },
  {
    name: "Singapore",
    href: Routes_Country.singapore
  }
];

export {
  countries ,
}
/* Routes_Country Not a pure module */
