// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconBuilding(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Building";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M8,16 L8,13 L6,13 L6,16 L0,16 L0,0 L14,0 L14,16 L8,16 Z M6,3 L6,6 L8,6 L8,3 L6,3 Z M2,3 L2,6 L4,6 L4,3 L2,3 Z M10,3 L10,6 L12,6 L12,3 L10,3 Z M6,8 L6,11 L8,11 L8,8 L6,8 Z M2,8 L2,11 L4,11 L4,8 L2,8 Z M10,8 L10,11 L12,11 L12,8 L10,8 Z",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = IconBuilding;

export {
  make ,
}
/* Icon Not a pure module */
