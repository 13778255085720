// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../Link/A.res.js";
import * as P from "../../Paragraph/P.res.js";
import * as Cart from "../../Cart/Cart.res.js";
import * as React from "react";
import * as Button from "../../Button/Button.res.js";
import * as Control from "../../Control/Control.res.js";
import * as IconCart from "../../../icons/IconCart.res.js";
import * as IconEdit from "../../../icons/IconEdit.res.js";
import * as IconFile from "../../../icons/IconFile.res.js";
import * as IconClose from "../../../icons/IconClose.res.js";
import * as IconCloud from "../../../icons/IconCloud.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconChevron from "../../../icons/IconChevron.res.js";
import * as IconMapView from "../../../icons/IconMapView.res.js";
import * as NavSignedIn from "../NavSignedIn/NavSignedIn.res.js";
import * as Routes_Page from "../../../../routes/common/Routes_Page.res.js";
import * as IconBuilding from "../../../icons/IconBuilding.res.js";
import * as IconListView from "../../../icons/IconListView.res.js";
import * as IconProducts from "../../../icons/IconProducts.res.js";
import * as NavSignedOut from "../NavSignOut/NavSignedOut.res.js";
import * as IconColocation from "../../../icons/IconColocation.res.js";
import * as Routes_Country from "../../../../routes/common/Routes_Country.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import LogoSvg from "Images/logo.svg";
import * as Routes_BlogPost from "../../../../routes/common/Routes_BlogPost.res.js";
import * as Routes_Location from "../../../../routes/common/Routes_Location.res.js";
import * as Routes_Provider from "../../../../routes/common/Routes_Provider.res.js";
import * as Routes_Resource from "../../../../routes/common/Routes_Resource.res.js";
import * as Routes_Services from "../../../../routes/common/Routes_Services.res.js";
import * as React$1 from "@headlessui/react";
import * as MobileSignedInNav from "./MobileSignedInNav.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as MobileNavigationCss from "./MobileNavigationCss.res.js";
import * as NavPopoverPanelMain from "../PopoverPanel/NavPopoverPanelMain/NavPopoverPanelMain.res.js";
import * as NavPopoverPanelAside from "../PopoverPanel/NavPopoverPanelAside/NavPopoverPanelAside.res.js";
import * as NavPopoverPanelFooter from "../PopoverPanel/NavPopoverPanelFooter/NavPopoverPanelFooter.res.js";
import * as IconDatacenterProviders from "../../../icons/IconDatacenterProviders.res.js";
import * as Routes_LocationBrochure from "../../../../routes/common/Routes_LocationBrochure.res.js";
import * as Routes_ProviderBrochure from "../../../../routes/common/Routes_ProviderBrochure.res.js";
import * as IconDatacenterColocation from "../../../icons/IconDatacenterColocation.res.js";

function MobileNavigation$SideMenuSubHeader(props) {
  var onClose = props.onClose;
  var onBack = props.onBack;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(IconChevron.make, {
                              color: "Gray",
                              direction: "Left",
                              className: MobileNavigationCss.size6
                            }),
                        JsxRuntime.jsx("span", {
                              children: "Back",
                              className: MobileNavigationCss.backText
                            })
                      ],
                      className: MobileNavigationCss.logoWrapper,
                      onClick: (function (_event) {
                          onBack(_event);
                          onClose(_event);
                        })
                    }),
                JsxRuntime.jsx("button", {
                      children: JsxRuntime.jsx(IconClose.make, {
                            size: "MD",
                            color: "Gray"
                          }),
                      onClick: (function (_event) {
                          onBack(_event);
                        })
                    })
              ],
              className: MobileNavigationCss.sideMenuHeader
            });
}

var SideMenuSubHeader = {
  make: MobileNavigation$SideMenuSubHeader
};

var initialState = {
  showColocation: false,
  showBareMetal: false,
  showCloud: false,
  showProviders: false,
  showKnowledge: false,
  showDashboard: false
};

function reducer(state, action) {
  switch (action) {
    case "ToggleColocation" :
        return {
                showColocation: !state.showColocation,
                showBareMetal: state.showBareMetal,
                showCloud: state.showCloud,
                showProviders: state.showProviders,
                showKnowledge: state.showKnowledge,
                showDashboard: state.showDashboard
              };
    case "ToggleBareMetal" :
        return {
                showColocation: state.showColocation,
                showBareMetal: !state.showBareMetal,
                showCloud: state.showCloud,
                showProviders: state.showProviders,
                showKnowledge: state.showKnowledge,
                showDashboard: state.showDashboard
              };
    case "ToggleCloud" :
        return {
                showColocation: state.showColocation,
                showBareMetal: state.showBareMetal,
                showCloud: !state.showCloud,
                showProviders: state.showProviders,
                showKnowledge: state.showKnowledge,
                showDashboard: state.showDashboard
              };
    case "ToggleProviders" :
        return {
                showColocation: state.showColocation,
                showBareMetal: state.showBareMetal,
                showCloud: state.showCloud,
                showProviders: !state.showProviders,
                showKnowledge: state.showKnowledge,
                showDashboard: state.showDashboard
              };
    case "ToggleKnowledge" :
        return {
                showColocation: state.showColocation,
                showBareMetal: state.showBareMetal,
                showCloud: state.showCloud,
                showProviders: state.showProviders,
                showKnowledge: !state.showKnowledge,
                showDashboard: state.showDashboard
              };
    case "ToggleDashboard" :
        return {
                showColocation: state.showColocation,
                showBareMetal: state.showBareMetal,
                showCloud: state.showCloud,
                showProviders: state.showProviders,
                showKnowledge: state.showKnowledge,
                showDashboard: !state.showDashboard
              };
    
  }
}

var countries = [
  {
    name: "United States",
    href: Routes_Country.unitedStates
  },
  {
    name: "China",
    href: Routes_Country.china
  },
  {
    name: "United Kingdom",
    href: Routes_Country.unitedKingdom
  },
  {
    name: "Germany",
    href: Routes_Country.germany
  },
  {
    name: "Canada",
    href: Routes_Country.canada
  },
  {
    name: "India",
    href: Routes_Country.india
  },
  {
    name: "Netherlands",
    href: Routes_Country.netherlands
  },
  {
    name: "France",
    href: Routes_Country.france
  },
  {
    name: "Australia",
    href: Routes_Country.australia
  },
  {
    name: "Japan",
    href: Routes_Country.japan
  },
  {
    name: "Brazil",
    href: Routes_Country.brazil
  },
  {
    name: "Singapore",
    href: Routes_Country.singapore
  }
];

function MobileNavigation(props) {
  var toggleAction = props.toggleAction;
  var cartQty = props.cartQty;
  var setCartQty = props.setCartQty;
  var toggleCart = props.toggleCart;
  var userLoginStatus = props.userLoginStatus;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          setCartQty(cartQty);
        }), []);
  var openSubDialog = function (openDialog) {
    dispatch(openDialog);
    toggleAction();
  };
  var tmp;
  tmp = userLoginStatus === "LoggedIn" ? JsxRuntime.jsx("li", {
          children: JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx("span", {
                        children: "My Dashboard",
                        className: MobileNavigationCss.firstLevelLink
                      }),
                  JsxRuntime.jsx(IconChevron.make, {
                        color: "Gray",
                        direction: "Right",
                        className: MobileNavigationCss.size6
                      })
                ],
                className: MobileNavigationCss.flexBetween
              }),
          className: MobileNavigationCss.linkContainer,
          onClick: (function (_event) {
              openSubDialog("ToggleDashboard");
            })
        }) : null;
  var tmp$1;
  tmp$1 = userLoginStatus === "LoggedIn" ? null : JsxRuntime.jsx(NavSignedOut.make, {
          device: "Mobile"
        });
  var tmp$2;
  tmp$2 = userLoginStatus === "LoggedIn" ? JsxRuntime.jsx(NavSignedIn.make, {
          setUserData: props.setUserData
        }) : null;
  var tmp$3;
  tmp$3 = userLoginStatus === "LoggedIn" ? JsxRuntime.jsx(MobileSignedInNav.make, {
          userData: props.userData,
          isConcierge: props.isConcierge,
          isNotOnlyProviderMember: props.isNotOnlyProviderMember
        }) : null;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(React$1.Dialog, {
                      open: props.toggleState,
                      onClose: (function () {
                          toggleAction();
                        }),
                      transition: true,
                      children: JsxRuntime.jsxs(React$1.DialogPanel, {
                            className: MobileNavigationCss.diaglogPanel,
                            transition: true,
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("div", {
                                                    children: JsxRuntime.jsx("a", {
                                                          children: JsxRuntime.jsx("img", {
                                                                alt: "Logo",
                                                                src: LogoSvg
                                                              }),
                                                          "aria-label": "Datacenters.com",
                                                          href: "/"
                                                        }),
                                                    className: MobileNavigationCss.logoWrapper
                                                  }),
                                              JsxRuntime.jsx("button", {
                                                    children: JsxRuntime.jsx(IconClose.make, {
                                                          size: "MD",
                                                          color: "Gray"
                                                        }),
                                                    onClick: (function (_event) {
                                                        toggleAction();
                                                      })
                                                  })
                                            ],
                                            className: MobileNavigationCss.sideMenuHeader
                                          }),
                                      JsxRuntime.jsxs("nav", {
                                            children: [
                                              JsxRuntime.jsxs("ul", {
                                                    children: [
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsxs("div", {
                                                                  children: [
                                                                    JsxRuntime.jsx("span", {
                                                                          children: "Colocation",
                                                                          className: MobileNavigationCss.firstLevelLink
                                                                        }),
                                                                    JsxRuntime.jsx(IconChevron.make, {
                                                                          color: "Gray",
                                                                          direction: "Right",
                                                                          className: MobileNavigationCss.size6
                                                                        })
                                                                  ],
                                                                  className: MobileNavigationCss.flexBetween
                                                                }),
                                                            className: MobileNavigationCss.linkContainer,
                                                            onClick: (function (_event) {
                                                                openSubDialog("ToggleColocation");
                                                              })
                                                          }),
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsxs("div", {
                                                                  children: [
                                                                    JsxRuntime.jsx("span", {
                                                                          children: "Bare Metal",
                                                                          className: MobileNavigationCss.firstLevelLink
                                                                        }),
                                                                    JsxRuntime.jsx(IconChevron.make, {
                                                                          color: "Gray",
                                                                          direction: "Right",
                                                                          className: MobileNavigationCss.size6
                                                                        })
                                                                  ],
                                                                  className: MobileNavigationCss.flexBetween
                                                                }),
                                                            className: MobileNavigationCss.linkContainer,
                                                            onClick: (function (_event) {
                                                                openSubDialog("ToggleBareMetal");
                                                              })
                                                          }),
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsxs("div", {
                                                                  children: [
                                                                    JsxRuntime.jsx("span", {
                                                                          children: "Cloud",
                                                                          className: MobileNavigationCss.firstLevelLink
                                                                        }),
                                                                    JsxRuntime.jsx(IconChevron.make, {
                                                                          color: "Gray",
                                                                          direction: "Right",
                                                                          className: MobileNavigationCss.size6
                                                                        })
                                                                  ],
                                                                  className: MobileNavigationCss.flexBetween
                                                                }),
                                                            className: MobileNavigationCss.linkContainer,
                                                            onClick: (function (_event) {
                                                                openSubDialog("ToggleCloud");
                                                              })
                                                          }),
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsxs("div", {
                                                                  children: [
                                                                    JsxRuntime.jsx("span", {
                                                                          children: "Providers",
                                                                          className: MobileNavigationCss.firstLevelLink
                                                                        }),
                                                                    JsxRuntime.jsx(IconChevron.make, {
                                                                          color: "Gray",
                                                                          direction: "Right",
                                                                          className: MobileNavigationCss.size6
                                                                        })
                                                                  ],
                                                                  className: MobileNavigationCss.flexBetween
                                                                }),
                                                            className: MobileNavigationCss.linkContainer,
                                                            onClick: (function (_event) {
                                                                openSubDialog("ToggleProviders");
                                                              })
                                                          }),
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsxs("div", {
                                                                  children: [
                                                                    JsxRuntime.jsx("span", {
                                                                          children: "Knowledge",
                                                                          className: MobileNavigationCss.firstLevelLink
                                                                        }),
                                                                    JsxRuntime.jsx(IconChevron.make, {
                                                                          color: "Gray",
                                                                          direction: "Right",
                                                                          className: MobileNavigationCss.size6
                                                                        })
                                                                  ],
                                                                  className: MobileNavigationCss.flexBetween
                                                                }),
                                                            className: MobileNavigationCss.linkContainer,
                                                            onClick: (function (_event) {
                                                                openSubDialog("ToggleKnowledge");
                                                              })
                                                          }),
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsxs(Control.make, {
                                                                  className: MobileNavigationCss.cartWrapper,
                                                                  onClick: (function (param) {
                                                                      toggleCart();
                                                                    }),
                                                                  children: [
                                                                    JsxRuntime.jsx(IconCart.make, {
                                                                          size: "MD",
                                                                          color: "DarkGray"
                                                                        }),
                                                                    "Cart",
                                                                    JsxRuntime.jsx("span", {
                                                                          children: String(props.totalCartQuantity),
                                                                          className: MobileNavigationCss.cartCounter
                                                                        })
                                                                  ]
                                                                }),
                                                            className: MobileNavigationCss.linkContainer
                                                          }),
                                                      tmp
                                                    ],
                                                    className: MobileNavigationCss.divider,
                                                    role: "list"
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      tmp$1,
                                                      JsxRuntime.jsx(Button.AsLink.make, {
                                                            href: Routes_Page.contact,
                                                            size: "MD",
                                                            color: "Teal",
                                                            expanded: true,
                                                            children: "Contact Us"
                                                          }),
                                                      tmp$2
                                                    ],
                                                    className: MobileNavigationCss.actionsButtons
                                                  })
                                            ],
                                            className: MobileNavigationCss.px6
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsx(Cart.make, {
                                    isOpen: props.isCartOpen,
                                    closeModal: toggleCart,
                                    setCartQty: setCartQty,
                                    userLoginStatus: userLoginStatus
                                  })
                            ]
                          })
                    }),
                JsxRuntime.jsx(React$1.Dialog, {
                      open: state.showBareMetal,
                      onClose: (function () {
                          dispatch("ToggleBareMetal");
                        }),
                      children: JsxRuntime.jsxs(React$1.DialogPanel, {
                            className: MobileNavigationCss.subDialogPanel,
                            transition: true,
                            children: [
                              JsxRuntime.jsx(MobileNavigation$SideMenuSubHeader, {
                                    onBack: (function (_event) {
                                        dispatch("ToggleBareMetal");
                                      }),
                                    onClose: (function (_event) {
                                        toggleAction();
                                      })
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Bare Metal",
                                            className: MobileNavigationCss.submenuTitle
                                          }),
                                      JsxRuntime.jsx(NavPopoverPanelMain.make, {
                                            children: JsxRuntime.jsx(NavPopoverPanelMain.Item.make, {
                                                  title: "Bare Metal RFP",
                                                  description: "A curated list of top providers offering different ranges of high-performance dedicated servers.",
                                                  icon: JsxRuntime.jsx(IconColocation.make, {
                                                        size: "SM",
                                                        color: "Teal"
                                                      }),
                                                  children: Caml_option.some(JsxRuntime.jsxs("ul", {
                                                            children: [
                                                              JsxRuntime.jsx("li", {
                                                                    children: JsxRuntime.jsx(A.make, {
                                                                          href: Routes_Services.BareMetal.index,
                                                                          className: MobileNavigationCss.linkContainer,
                                                                          children: "Bare Metal Services"
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx("li", {
                                                                    children: JsxRuntime.jsx(A.make, {
                                                                          href: Routes_Services.BareMetal.enzu,
                                                                          className: MobileNavigationCss.linkContainer,
                                                                          children: "Enzu Bare Metal"
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx("li", {
                                                                    children: JsxRuntime.jsx(A.make, {
                                                                          href: Routes_Services.BareMetal.zenlayer,
                                                                          className: MobileNavigationCss.linkContainer,
                                                                          children: "Zenlayer Bare Metal"
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx("li", {
                                                                    children: JsxRuntime.jsx(A.make, {
                                                                          href: Routes_Services.BareMetal.hivelocity,
                                                                          className: MobileNavigationCss.linkContainer,
                                                                          children: "Hivelocity Bare Metal"
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx("li", {
                                                                    children: JsxRuntime.jsx(A.make, {
                                                                          href: Routes_Services.BareMetal.evocative,
                                                                          className: MobileNavigationCss.linkContainer,
                                                                          children: "Evocative Bare Metal"
                                                                        })
                                                                  })
                                                            ],
                                                            className: MobileNavigationCss.listContainer
                                                          }))
                                                })
                                          }),
                                      JsxRuntime.jsx(NavPopoverPanelFooter.make, {
                                            title: "Bare Metal Project",
                                            description: "Submit an application and get proposals from multiple providers to compare prices.",
                                            callToAction: "Start Project",
                                            href: Routes_Project.BareMetal.$$new
                                          })
                                    ],
                                    className: MobileNavigationCss.subMenuContent
                                  })
                            ]
                          })
                    }),
                JsxRuntime.jsx(React$1.Dialog, {
                      open: state.showColocation,
                      onClose: (function () {
                          dispatch("ToggleColocation");
                        }),
                      children: JsxRuntime.jsx(React$1.DialogPanel, {
                            className: MobileNavigationCss.subDialogPanel,
                            transition: true,
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(MobileNavigation$SideMenuSubHeader, {
                                          onBack: (function (_event) {
                                              dispatch("ToggleColocation");
                                            }),
                                          onClose: (function (_event) {
                                              toggleAction();
                                            })
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("div", {
                                                  children: "Colocation",
                                                  className: MobileNavigationCss.submenuTitle
                                                }),
                                            JsxRuntime.jsx(NavPopoverPanelMain.make, {
                                                  children: JsxRuntime.jsx(NavPopoverPanelMain.Item.make, {
                                                        title: "Data Center Locations",
                                                        description: "Select the country or view all locations by clicking on a link below.",
                                                        icon: JsxRuntime.jsx(IconMapView.make, {
                                                              size: "SM",
                                                              color: "Teal"
                                                            }),
                                                        children: Caml_option.some(countries.length !== 0 ? JsxRuntime.jsxs("div", {
                                                                    children: [
                                                                      JsxRuntime.jsx("div", {
                                                                            children: Belt_Array.mapWithIndex(countries, (function (index, country) {
                                                                                    return JsxRuntime.jsx("div", {
                                                                                                children: JsxRuntime.jsx(A.make, {
                                                                                                      href: country.href,
                                                                                                      className: MobileNavigationCss.countryText,
                                                                                                      children: country.name
                                                                                                    }, String(index)),
                                                                                                className: MobileNavigationCss.py3
                                                                                              }, String(index));
                                                                                  })),
                                                                            className: MobileNavigationCss.countriesList
                                                                          }),
                                                                      JsxRuntime.jsx("div", {
                                                                            children: JsxRuntime.jsx(A.make, {
                                                                                  href: Routes_Location.index,
                                                                                  className: MobileNavigationCss.viewAllContainer,
                                                                                  children: "View All Data Centers"
                                                                                })
                                                                          })
                                                                    ],
                                                                    className: MobileNavigationCss.sectioncCountriesList
                                                                  }) : JsxRuntime.jsx("div", {
                                                                    children: JsxRuntime.jsx(P.make, {
                                                                          children: "No Data Centers Found"
                                                                        }),
                                                                    className: MobileNavigationCss.noDataContainer
                                                                  }))
                                                      })
                                                }),
                                            JsxRuntime.jsx(NavPopoverPanelFooter.make, {
                                                  title: "Colocation Project",
                                                  description: "Get pricing directly from multiple providers by submitting your colocation project.",
                                                  callToAction: "Start Project",
                                                  href: Routes_Project.Colocation.$$new
                                                }),
                                            JsxRuntime.jsxs(NavPopoverPanelAside.make, {
                                                  title: "More",
                                                  children: [
                                                    JsxRuntime.jsx(NavPopoverPanelAside.Item.make, {
                                                          title: "Data Center Directory",
                                                          icon: JsxRuntime.jsx(IconProducts.make, {
                                                                size: "SM",
                                                                color: "Gray"
                                                              }),
                                                          href: Routes_Location.index
                                                        }),
                                                    JsxRuntime.jsx(NavPopoverPanelAside.Item.make, {
                                                          title: "Colocation Overview",
                                                          icon: JsxRuntime.jsx(IconListView.make, {
                                                                size: "SM",
                                                                color: "Gray"
                                                              }),
                                                          href: Routes_Services.Colocation.index
                                                        }),
                                                    JsxRuntime.jsx(NavPopoverPanelAside.Item.make, {
                                                          title: "Data Center Brochures",
                                                          icon: JsxRuntime.jsx(IconFile.make, {
                                                                size: "SM",
                                                                color: "Gray"
                                                              }),
                                                          href: Routes_LocationBrochure.index
                                                        }),
                                                    JsxRuntime.jsx(NavPopoverPanelAside.Item.make, {
                                                          title: "Provider Brochures",
                                                          icon: JsxRuntime.jsx(IconFile.make, {
                                                                size: "SM",
                                                                color: "Gray"
                                                              }),
                                                          href: Routes_ProviderBrochure.index
                                                        }),
                                                    JsxRuntime.jsx(NavPopoverPanelAside.Item.make, {
                                                          title: "Colocation Providers",
                                                          icon: JsxRuntime.jsx(IconDatacenterColocation.make, {
                                                                size: "SM",
                                                                color: "Gray"
                                                              }),
                                                          href: Routes_Provider.colocation
                                                        }),
                                                    JsxRuntime.jsx(NavPopoverPanelAside.Item.make, {
                                                          title: "All Services Providers",
                                                          icon: JsxRuntime.jsx(IconDatacenterProviders.make, {
                                                                size: "SM",
                                                                color: "Gray"
                                                              }),
                                                          href: Routes_Provider.index
                                                        })
                                                  ]
                                                })
                                          ],
                                          className: MobileNavigationCss.subMenuContent
                                        })
                                  ]
                                })
                          })
                    }),
                JsxRuntime.jsx(React$1.Dialog, {
                      open: state.showCloud,
                      onClose: (function () {
                          dispatch("ToggleCloud");
                        }),
                      children: JsxRuntime.jsxs(React$1.DialogPanel, {
                            className: MobileNavigationCss.subDialogPanel,
                            transition: true,
                            children: [
                              JsxRuntime.jsx(MobileNavigation$SideMenuSubHeader, {
                                    onBack: (function (_event) {
                                        dispatch("ToggleCloud");
                                      }),
                                    onClose: (function (_event) {
                                        toggleAction();
                                      })
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Cloud",
                                            className: MobileNavigationCss.submenuTitle
                                          }),
                                      JsxRuntime.jsx(NavPopoverPanelMain.make, {
                                            children: JsxRuntime.jsx(NavPopoverPanelMain.Item.make, {
                                                  title: "Multicloud",
                                                  description: "Multicloud Deployment to Any Cloud, Anywhere, Anytime.",
                                                  icon: JsxRuntime.jsx(IconCloud.make, {
                                                        size: "MD",
                                                        color: "Teal",
                                                        className: "w-8"
                                                      }),
                                                  href: Routes_Services.Multicloud.index
                                                })
                                          }),
                                      JsxRuntime.jsx(NavPopoverPanelFooter.make, {
                                            title: "Cloud Project",
                                            description: "Submit an application and get proposals from multiple providers to compare prices.",
                                            callToAction: "Start Project",
                                            href: Routes_Project.Cloud.$$new
                                          })
                                    ],
                                    className: MobileNavigationCss.subMenuContent
                                  })
                            ]
                          })
                    }),
                JsxRuntime.jsx(React$1.Dialog, {
                      open: state.showKnowledge,
                      onClose: (function () {
                          dispatch("ToggleKnowledge");
                        }),
                      children: JsxRuntime.jsxs(React$1.DialogPanel, {
                            className: MobileNavigationCss.subDialogPanel,
                            transition: true,
                            children: [
                              JsxRuntime.jsx(MobileNavigation$SideMenuSubHeader, {
                                    onBack: (function (_event) {
                                        dispatch("ToggleKnowledge");
                                      }),
                                    onClose: (function (_event) {
                                        toggleAction();
                                      })
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Knowledge",
                                            className: MobileNavigationCss.submenuTitle
                                          }),
                                      JsxRuntime.jsxs(NavPopoverPanelMain.make, {
                                            children: [
                                              JsxRuntime.jsx(NavPopoverPanelMain.Item.make, {
                                                    title: "Resources",
                                                    description: "A collection of downloadable data centers resources.",
                                                    icon: JsxRuntime.jsx(IconFile.make, {
                                                          size: "SM",
                                                          color: "Teal"
                                                        }),
                                                    children: Caml_option.some(JsxRuntime.jsx("ul", {
                                                              children: Belt_Array.map(props.resources, (function (resource) {
                                                                      var slug = resource.slug;
                                                                      return JsxRuntime.jsx("li", {
                                                                                  children: slug === "all-resources" ? JsxRuntime.jsx(A.make, {
                                                                                          href: Routes_Resource.resourcesIndex,
                                                                                          className: MobileNavigationCss.linkContainer,
                                                                                          children: resource.navigationName
                                                                                        }) : JsxRuntime.jsx(A.make, {
                                                                                          href: Routes_Resource.category(resource.slug),
                                                                                          className: MobileNavigationCss.linkContainer,
                                                                                          children: resource.navigationName
                                                                                        })
                                                                                }, resource.slug);
                                                                    })),
                                                              className: MobileNavigationCss.listContainer
                                                            }))
                                                  }),
                                              JsxRuntime.jsx(NavPopoverPanelMain.Item.make, {
                                                    title: "Blog",
                                                    description: "Data Center News and Trending Topics",
                                                    icon: JsxRuntime.jsx(IconEdit.make, {
                                                          size: "SM",
                                                          color: "Teal"
                                                        }),
                                                    href: Routes_BlogPost.news
                                                  })
                                            ]
                                          })
                                    ],
                                    className: MobileNavigationCss.subMenuContent
                                  })
                            ]
                          })
                    }),
                JsxRuntime.jsx(React$1.Dialog, {
                      open: state.showProviders,
                      onClose: (function () {
                          dispatch("ToggleProviders");
                        }),
                      children: JsxRuntime.jsxs(React$1.DialogPanel, {
                            className: MobileNavigationCss.subDialogPanel,
                            transition: true,
                            children: [
                              JsxRuntime.jsx(MobileNavigation$SideMenuSubHeader, {
                                    onBack: (function (_event) {
                                        dispatch("ToggleProviders");
                                      }),
                                    onClose: (function (_event) {
                                        toggleAction();
                                      })
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Providers",
                                            className: MobileNavigationCss.submenuTitle
                                          }),
                                      JsxRuntime.jsxs(NavPopoverPanelMain.make, {
                                            children: [
                                              JsxRuntime.jsx(NavPopoverPanelMain.Item.make, {
                                                    title: "Providers",
                                                    description: "A curated list of providers from all over the world.",
                                                    icon: JsxRuntime.jsx(IconBuilding.make, {
                                                          size: "SM",
                                                          color: "Teal"
                                                        }),
                                                    children: Caml_option.some(JsxRuntime.jsxs("ul", {
                                                              children: [
                                                                JsxRuntime.jsx("li", {
                                                                      children: JsxRuntime.jsx(A.make, {
                                                                            href: Routes_Provider.index,
                                                                            className: MobileNavigationCss.linkContainer,
                                                                            children: "All providers"
                                                                          })
                                                                    }),
                                                                JsxRuntime.jsx("li", {
                                                                      children: JsxRuntime.jsx(A.make, {
                                                                            href: Routes_Provider.colocation,
                                                                            className: MobileNavigationCss.linkContainer,
                                                                            children: "Colocation Providers"
                                                                          })
                                                                    }),
                                                                JsxRuntime.jsx("li", {
                                                                      children: JsxRuntime.jsx(A.make, {
                                                                            href: Routes_Provider.bareMetal,
                                                                            className: MobileNavigationCss.linkContainer,
                                                                            children: "Bare Metal Providers"
                                                                          })
                                                                    }),
                                                                JsxRuntime.jsx("li", {
                                                                      children: JsxRuntime.jsx(A.make, {
                                                                            href: Routes_Provider.cloud,
                                                                            className: MobileNavigationCss.linkContainer,
                                                                            children: "Cloud Providers"
                                                                          })
                                                                    })
                                                              ],
                                                              className: MobileNavigationCss.listContainer
                                                            }))
                                                  }),
                                              JsxRuntime.jsx(NavPopoverPanelMain.Item.make, {
                                                    title: "Provider Brochures",
                                                    description: "Brochures with helpful information about providers.",
                                                    icon: JsxRuntime.jsx(IconFile.make, {
                                                          size: "SM",
                                                          color: "Teal"
                                                        }),
                                                    href: Routes_ProviderBrochure.index
                                                  })
                                            ]
                                          })
                                    ],
                                    className: MobileNavigationCss.subMenuContent
                                  })
                            ]
                          })
                    }),
                JsxRuntime.jsx(React$1.Dialog, {
                      open: state.showDashboard,
                      onClose: (function () {
                          dispatch("ToggleDashboard");
                        }),
                      children: JsxRuntime.jsxs(React$1.DialogPanel, {
                            className: MobileNavigationCss.subDialogPanel,
                            transition: true,
                            children: [
                              JsxRuntime.jsx(MobileNavigation$SideMenuSubHeader, {
                                    onBack: (function (_event) {
                                        dispatch("ToggleDashboard");
                                      }),
                                    onClose: (function (_event) {
                                        toggleAction();
                                      })
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: tmp$3,
                                    className: MobileNavigationCss.subMenuContent
                                  })
                            ]
                          })
                    })
              ]
            });
}

var Css;

var make = MobileNavigation;

export {
  Css ,
  SideMenuSubHeader ,
  initialState ,
  reducer ,
  countries ,
  make ,
}
/* A Not a pure module */
