// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "@headlessui/react";
import * as JsxRuntime from "react/jsx-runtime";

function rowsToClass(rows) {
  if (rows !== 1) {
    if (rows !== 2) {
      return Cx.cx([" grid-rows-popover-panel"]);
    } else {
      return Cx.cx(["grid-rows-popover-panel"]);
    }
  } else {
    return Cx.cx(["grid-rows-1"]);
  }
}

function colsToClass(cols) {
  switch (cols) {
    case 1 :
        return Cx.cx(["grid-cols-1"]);
    case 2 :
        return Cx.cx(["grid-cols-2"]);
    case 3 :
        return Cx.cx(["grid-cols-3"]);
    default:
      return Cx.cx(["grid-cols-2"]);
  }
}

function NavPopoverPanel(props) {
  return JsxRuntime.jsx(React.PopoverPanel, {
              anchor: "bottom",
              transition: true,
              className: Cx.cx([
                    props.className,
                    colsToClass(props.cols),
                    rowsToClass(props.rows)
                  ]),
              children: props.children
            });
}

var make = NavPopoverPanel;

export {
  rowsToClass ,
  colsToClass ,
  make ,
}
/* @headlessui/react Not a pure module */
