// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";

function category(slug) {
  return "/news/categories/" + encodeURI(slug);
}

function newsCategories(category) {
  return "/news/categories/" + category.toLowerCase();
}

function show(slug) {
  return "/news/" + encodeURI(slug);
}

function edit(id) {
  return "/dashboard/blog_posts/" + (ID.toString(id) + "/edit");
}

var Dashboard = {
  index: "/dashboard/blog_posts",
  $$new: "/dashboard/blog_posts/new",
  edit: edit,
  analytics: "/dashboard/blog_posts/analytics"
};

var news = "/news";

export {
  news ,
  category ,
  newsCategories ,
  show ,
  Dashboard ,
}
/* ID Not a pure module */
