// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../Link/A.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as NavPopoverPanelAsideCss from "./NavPopoverPanelAsideCss.res.js";

function NavPopoverPanelAside$Item(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: props.icon,
                      className: NavPopoverPanelAsideCss.iconContainer
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(A.make, {
                            href: props.href,
                            className: NavPopoverPanelAsideCss.titleLink,
                            children: props.title
                          })
                    })
              ],
              className: NavPopoverPanelAsideCss.itemWrapper
            });
}

var Item = {
  make: NavPopoverPanelAside$Item
};

function NavPopoverPanelAside(props) {
  return JsxRuntime.jsxs("section", {
              children: [
                JsxRuntime.jsx("div", {
                      children: props.title,
                      className: NavPopoverPanelAsideCss.titleText
                    }),
                props.children
              ],
              className: NavPopoverPanelAsideCss.popoverPanelAsideWrapper
            });
}

var Css;

var make = NavPopoverPanelAside;

export {
  Css ,
  Item ,
  make ,
}
/* A Not a pure module */
