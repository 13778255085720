// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var outerWrapper = Cx.cx([
      "flex",
      "flex-col",
      "flex-wrap",
      "top-0",
      "right-0",
      "left-0",
      "!z-1000",
      "w-full"
    ]);

var popover = Cx.cx([
      "relative",
      "group"
    ]);

var groupOpen = Cx.cx([
      "cursor-pointer",
      "transition-transform",
      "duration-200",
      "ease-in-out",
      "group-data-[open]:rotate-180",
      "group-data-[open]:text-teal-500"
    ]);

var popoverButton = Cx.cx([
      "flex",
      "items-center",
      "gap-x-1",
      "text-sm",
      "font-semibold",
      "leading-6",
      "text-white",
      "hover:text-teal",
      "focus:outline-none"
    ]);

var popoverGroup = Cx.cx([
      "hidden",
      "lg:flex ",
      "xl:gap-x-8",
      "lg:gap-x-4"
    ]);

var popoverPanel = Cx.cx([
      "grid",
      "grid-flow-col",
      "[--anchor-padding:1rem]",
      "[--anchor-gap:2rem]",
      "gap-3",
      "absolute",
      "-left-8",
      "top-full",
      "z-10",
      "max-w-5xl",
      "overflow-hidden",
      "rounded-lg",
      "bg-gray-50",
      "p-2.5",
      "shadow-lg",
      "ring-1",
      "ring-gray-900/5",
      "transition",
      "data-[closed]:translate-y-1",
      "data-[closed]:opacity-0",
      "data-[enter]:duration-200",
      "data-[leave]:duration-150",
      "data-[enter]:ease-out",
      "data-[leave]:ease-in"
    ]);

var navWrapper = Cx.cx([
      "flex",
      "items-center",
      "justify-between",
      "py-5",
      "lg:px-4",
      "xl:px-12",
      "px-5"
    ]);

var logoWrapper = Cx.cx([
      "flex",
      "lg:flex-1",
      "max-w-[200px]"
    ]);

var viewAllContainer = Cx.cx([
      "text-black",
      "text-base",
      "font-semibold",
      "leading-5",
      "hover:text-teal"
    ]);

var linkContainer = Cx.cx([
      "text-black",
      "text-sm",
      "font-medium",
      "leading-5",
      "hover:text-teal"
    ]);

var countryText = Cx.cx([
      linkContainer,
      "truncate",
      "w-[120px]",
      "hover:text-teal"
    ]);

var spanText = Cx.cx([
      "text-gray-400",
      "text-base",
      "ml-1"
    ]);

var noDataContainer = Cx.cx([
      "flex",
      "flex-col",
      "gap-1"
    ]);

var noDataText = Cx.cx([
      "text-black",
      "text-sm",
      "font-medium",
      "leading-5"
    ]);

var sectioncCountriesList = Cx.cx([
      "flex",
      "flex-col",
      "gap-4"
    ]);

var countriesList = Cx.cx([
      "grid",
      "grid-cols-3",
      "place-items-start",
      "gap-2"
    ]);

var listContainer = Cx.cx([
      "flex",
      "flex-col",
      "gap-2"
    ]);

var signInNavWrapper = Cx.cx([
      "hidden",
      "lg:flex",
      "justify-end"
    ]);

var openMenuIcon = Cx.cx([
      "w-6",
      "h-8"
    ]);

var openMenuButton = Cx.cx(["lg:hidden"]);

export {
  outerWrapper ,
  popover ,
  groupOpen ,
  popoverButton ,
  popoverGroup ,
  popoverPanel ,
  navWrapper ,
  logoWrapper ,
  viewAllContainer ,
  linkContainer ,
  countryText ,
  spanText ,
  noDataContainer ,
  noDataText ,
  sectioncCountriesList ,
  countriesList ,
  listContainer ,
  signInNavWrapper ,
  openMenuIcon ,
  openMenuButton ,
}
/* outerWrapper Not a pure module */
