// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Events from "../../../libs/Events.res.js";
import * as Control from "../Control/Control.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as CartList from "./CartList.res.js";
import * as CheckOut from "../../modals/CheckOut/CheckOut.res.js";
import * as Keyboard from "../../../libs/Keyboard.res.js";
import * as IconClose from "../../icons/IconClose.res.js";
import * as CartScss from "./Cart.scss";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_Marketplace from "../../../routes/common/Routes_Marketplace.res.js";

var css = CartScss;

var initialState_items = [];

var initialState = {
  currentPage: "CartList",
  fetchingStatus: "Fetching",
  items: initialState_items,
  serverError: undefined
};

function Cart(props) {
  var setCartQty = props.setCartQty;
  var closeModal = props.closeModal;
  var isOpen = props.isOpen;
  var timeoutId = React.useRef(undefined);
  var prevIsOpen = Hooks.usePrevious(isOpen);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchCartItems" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            currentPage: state.currentPage,
                            fetchingStatus: "Fetching",
                            items: state.items,
                            serverError: state.serverError
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api.fetchCartItems(), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "UpdateCartItems",
                                                    _0: x._0.products
                                                  });
                                      } else {
                                        return dispatch({
                                                    TAG: "ServerError",
                                                    _0: "Something went wrong, please try again!"
                                                  });
                                      }
                                    }));
                            })
                        };
              case "OpenCheckOutPage" :
                  return {
                          TAG: "Update",
                          _0: {
                            currentPage: "CheckoutForm",
                            fetchingStatus: state.fetchingStatus,
                            items: state.items,
                            serverError: state.serverError
                          }
                        };
              case "ResetState" :
                  return {
                          TAG: "Update",
                          _0: {
                            currentPage: "CartList",
                            fetchingStatus: "Fetching",
                            items: state.items,
                            serverError: state.serverError
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateCartItems" :
                  return {
                          TAG: "Update",
                          _0: {
                            currentPage: state.currentPage,
                            fetchingStatus: "Success",
                            items: action._0,
                            serverError: state.serverError
                          }
                        };
              case "UpdateItemToCart" :
                  var qty = action._1;
                  var productId = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: state,
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api.addToCart(qty, productId), (function (x) {
                                      if (x.TAG !== "Ok") {
                                        return dispatch({
                                                    TAG: "ServerError",
                                                    _0: "Something went wrong, please try again!"
                                                  });
                                      }
                                      setCartQty(x._0.cartQty);
                                      dispatch("FetchCartItems");
                                    }));
                            })
                        };
              case "ServerError" :
                  var serverError = action._0;
                  if (serverError !== undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              currentPage: state.currentPage,
                              fetchingStatus: state.fetchingStatus,
                              items: state.items,
                              serverError: serverError
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                var x = timeoutId.current;
                                if (x !== undefined) {
                                  clearTimeout(Caml_option.valFromOption(x));
                                }
                                timeoutId.current = Caml_option.some(setTimeout((function () {
                                            dispatch({
                                                  TAG: "ServerError",
                                                  _0: undefined
                                                });
                                          }), 5000));
                              })
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              currentPage: state.currentPage,
                              fetchingStatus: state.fetchingStatus,
                              items: state.items,
                              serverError: undefined
                            }
                          };
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchCartItems");
        }), []);
  React.useEffect((function () {
          return Events.Subscriptions.subscribeToKeyDown(function ($$event) {
                      Keyboard.Dom.onEsc($$event, (function () {
                              closeModal();
                            }));
                    });
        }), [closeModal]);
  React.useEffect((function () {
          return (function () {
                    var x = timeoutId.current;
                    if (x !== undefined) {
                      clearTimeout(Caml_option.valFromOption(x));
                      return ;
                    }
                    
                  });
        }), []);
  React.useEffect((function () {
          if (prevIsOpen === undefined) {
            return ;
          }
          var timeoutId = prevIsOpen ? setTimeout((function () {
                    dispatch("ResetState");
                  }), 500) : setTimeout((function () {
                    dispatch("FetchCartItems");
                  }), 1000);
          return (function () {
                    clearTimeout(timeoutId);
                  });
        }), [prevIsOpen]);
  var tmp;
  if (isOpen) {
    var str = state.serverError;
    var match$1 = state.currentPage;
    tmp = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx("div", {
                  className: css.modalOverlay,
                  onClick: (function (param) {
                      closeModal();
                    })
                }),
            JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsxs("div", {
                        children: [
                          str !== undefined ? str : null,
                          JsxRuntime.jsx(Control.make, {
                                className: css.closeOverlayButton,
                                onClick: (function (param) {
                                    closeModal();
                                  }),
                                children: JsxRuntime.jsx(IconClose.make, {
                                      title: "Close",
                                      size: "MD",
                                      color: "Gray"
                                    })
                              }),
                          match$1 === "CheckoutForm" ? JsxRuntime.jsx(CheckOut.make, {
                                  openCheckOutSuccess: (function () {
                                      Url.visit(Routes_Marketplace.Cart.thanks);
                                    }),
                                  setCartQty: setCartQty
                                }) : JsxRuntime.jsx(CartList.make, {
                                  items: state.items,
                                  openCheckOutPage: (function () {
                                      dispatch("OpenCheckOutPage");
                                    }),
                                  updateItemToCart: (function (productId, qty) {
                                      dispatch({
                                            TAG: "UpdateItemToCart",
                                            _0: productId,
                                            _1: qty
                                          });
                                    }),
                                  userLoginStatus: props.userLoginStatus,
                                  onCartListLoad: (function () {
                                      dispatch("FetchCartItems");
                                    })
                                })
                        ],
                        className: css.modalContents
                      }),
                  className: Cx.cx([css.sideModal]),
                  id: "sideModal"
                })
          ],
          className: Cx.cx([css.sideModalContainer]),
          id: "sideModalContainer"
        });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(tmp)
            });
}

var make = Cart;

export {
  css ,
  initialState ,
  make ,
}
/* css Not a pure module */
