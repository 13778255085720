// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconCart(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Cart";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("g", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("g", {
                                children: JsxRuntime.jsx("g", {
                                      children: JsxRuntime.jsx("path", {
                                            className: "st0",
                                            d: "M4.4,19.9c1,0,1.7,0.8,1.7,1.7c0,1-0.8,1.7-1.7,1.7s-1.7-0.8-1.7-1.7\n					C2.6,20.7,3.4,19.9,4.4,19.9z M11.3,19.9c1,0,1.7,0.8,1.7,1.7c0,1-0.8,1.7-1.7,1.7s-1.7-0.8-1.7-1.7\n					C9.6,20.7,10.4,19.9,11.3,19.9z M2.6,8.6l0.2,2.6h12.3c0.5,0,0.9,0.4,0.9,0.9c0,0.1,0,0.3-0.1,0.4l-2.6,5.2\n					c-0.1,0.3-0.4,0.5-0.8,0.5H3.4c-0.4,0-0.8-0.3-0.9-0.8l-0.8-6.2l0,0L1.6,9.5H0V8.6H2.6z M14.6,12.5H3.2l0.6,4.4h8.5L14.6,12.5z"
                                          }),
                                      fill: Icon.mapColor(props.color)
                                    }),
                                transform: "translate(370.000000, 210.000000)"
                              }),
                          transform: "translate(-370.000000, -218.000000)"
                        })
                  })
            });
}

var make = IconCart;

export {
  make ,
}
/* Icon Not a pure module */
