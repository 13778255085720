// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as MarketplaceFilterData from "../../bundles/product-index/filters/MarketplaceFilterData.res.js";

function reserve(id, category) {
  return "/marketplace/" + (category + ("/" + (ID.toString(id) + "/reserve")));
}

function reserveConfirmation(id, productId, category) {
  return "/marketplace/" + (category + ("/" + (ID.toString(productId) + ("/reserve_complete/" + ID.toString(id)))));
}

function search(filters) {
  var queryString = MarketplaceFilterData.Input.toQueryString(filters);
  var tmp = queryString === "" ? "" : "?" + queryString;
  return "/marketplace/" + (filters.category + tmp);
}

var Cart = {
  thanks: "/marketplace/cart_reservation_complete"
};

function index(id) {
  return "/marketplace/colocation-pricing?providerIds" + (encodeURIComponent("[]") + ("=" + ID.toString(id)));
}

var Colocation = {
  index: index
};

var Provider = {
  Colocation: Colocation
};

var colocation = "/marketplace/colocation-pricing";

var index$1 = "/marketplace";

export {
  reserve ,
  reserveConfirmation ,
  search ,
  colocation ,
  index$1 as index,
  Cart ,
  Provider ,
}
/* ID Not a pure module */
