// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../../../Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as NavPopoverPanelFooterCss from "./NavPopoverPanelFooterCss.res.js";

function NavPopoverPanelFooter(props) {
  return JsxRuntime.jsx("section", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: props.title,
                                    className: NavPopoverPanelFooterCss.titleText
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("p", {
                                          children: props.description,
                                          className: NavPopoverPanelFooterCss.footerText
                                        }),
                                    className: NavPopoverPanelFooterCss.descriptionContainer
                                  })
                            ],
                            className: NavPopoverPanelFooterCss.textContainer
                          }),
                      JsxRuntime.jsx(Button.AsLink.make, {
                            href: props.href,
                            size: "MD",
                            color: "Teal",
                            children: props.callToAction
                          })
                    ],
                    className: NavPopoverPanelFooterCss.contentContainer
                  }),
              className: NavPopoverPanelFooterCss.sectionContainer
            });
}

var Css;

var make = NavPopoverPanelFooter;

export {
  Css ,
  make ,
}
/* Button Not a pure module */
