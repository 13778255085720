// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Money from "../../../libs/Money.res.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as IconDelete from "../../../styleguide/icons/IconDelete.res.js";
import * as WithDataProps from "../../../styleguide/components/WithDataProps/WithDataProps.res.js";
import * as CartItemScss from "./CartItem.scss";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_Marketplace from "../../../routes/common/Routes_Marketplace.res.js";

var css = CartItemScss;

function CartItem(props) {
  var __isExpired = props.isExpired;
  var updateQty = props.updateQty;
  var qty = props.qty;
  var isExpired = __isExpired !== undefined ? __isExpired : false;
  var tmp;
  tmp = props.userLoginStatus === "LoggedIn" ? JsxRuntime.jsx(WithDataProps.make, {
          props: {
            "data-test-id": "cart-item-price"
          },
          children: JsxRuntime.jsx("div", {
                children: Money.formatWithCents(props.price) + "/month",
                className: css.price
              })
        }) : null;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        isExpired ? JsxRuntime.jsx("div", {
                                children: "Expired",
                                className: css.exclusiveBanner
                              }) : null,
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx("img", {
                                            alt: "test",
                                            src: props.image
                                          }),
                                      className: css.imageHolder
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: props.category,
                                      className: css.category
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: props.providerName + (": " + props.name),
                                      className: css.title
                                    }),
                                tmp,
                                JsxRuntime.jsx("div", {
                                      children: isExpired ? "Sale ended" : "Sale ends on " + Format(props.saleEnd, "MMM d"),
                                      className: css.saleNote
                                    })
                              ],
                              className: css.cartDetail
                            }),
                        isExpired ? null : JsxRuntime.jsxs("div", {
                                children: [
                                  qty !== 1 ? JsxRuntime.jsx("span", {
                                          className: Cx.cx([
                                                css.counterIcon,
                                                css.minus
                                              ]),
                                          onClick: (function (param) {
                                              updateQty(-1);
                                            })
                                        }) : JsxRuntime.jsx("span", {
                                          className: Cx.cx([
                                                css.counterIcon,
                                                css.minus
                                              ])
                                        }),
                                  String(qty),
                                  JsxRuntime.jsx("span", {
                                        className: Cx.cx([
                                              css.counterIcon,
                                              css.plus
                                            ]),
                                        onClick: (function (param) {
                                            updateQty(1);
                                          })
                                      })
                                ],
                                className: css.cartCounter
                              }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Control.make, {
                                    onClick: (function (param) {
                                        updateQty(0);
                                      }),
                                    children: JsxRuntime.jsx(IconDelete.make, {
                                          size: "SM",
                                          color: "DarkGray",
                                          className: css.iconHoverColor
                                        })
                                  }),
                              className: css.deleteIcon
                            })
                      ],
                      className: css.cartItem
                    }),
                isExpired ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                "Unfortunately, this product has expired. ",
                                JsxRuntime.jsx(A.make, {
                                      href: Routes_Marketplace.colocation,
                                      targetBlank: true,
                                      className: css.title,
                                      children: "Go to the marketplace"
                                    }),
                                " to select another one."
                              ],
                              className: css.desc
                            }),
                        className: css.expiredDiv
                      }) : null
              ]
            });
}

var make = CartItem;

export {
  css ,
  make ,
}
/* css Not a pure module */
