// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconListView(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "List View";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M1,0 L15,0 C15.5522847,0 16,0.44771525 16,1 L16,10 C16,10.5522847 15.5522847,11 15,11 L1,11 C0.44771525,11 0,10.5522847 0,10 L0,1 C0,0.44771525 0.44771525,0 1,0 Z M2,2 L2,9 L14,9 L14,2 L2,2 Z M1,13 L11,13 C11.5522847,13 12,13.4477153 12,14 L12,15 C12,15.5522847 11.5522847,16 11,16 L1,16 C0.44771525,16 0,15.5522847 0,15 L0,14 C0,13.4477153 0.44771525,13 1,13 Z",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = IconListView;

export {
  make ,
}
/* Icon Not a pure module */
