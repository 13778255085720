// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IconChevronDown from "../../../../../icons/IconChevronDown.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DesktopNavDisclosureCss from "./DesktopNavDisclosureCss.res.js";

function DesktopNavDisclosure(props) {
  return JsxRuntime.jsxs("details", {
              children: [
                JsxRuntime.jsxs("summary", {
                      children: [
                        JsxRuntime.jsx("span", {
                              children: props.label,
                              "aria-expanded": false,
                              className: DesktopNavDisclosureCss.disclosureButtonText
                            }),
                        JsxRuntime.jsx(IconChevronDown.make, {
                              size: "SM",
                              color: "Gray",
                              className: DesktopNavDisclosureCss.groupOpenIcon
                            })
                      ],
                      className: DesktopNavDisclosureCss.disclosureButton
                    }),
                JsxRuntime.jsx("div", {
                      children: props.children,
                      className: DesktopNavDisclosureCss.disclosurePanel
                    })
              ],
              className: DesktopNavDisclosureCss.disclosure,
              name: "my-dashboard"
            });
}

var Css;

var make = DesktopNavDisclosure;

export {
  Css ,
  make ,
}
/* IconChevronDown Not a pure module */
