// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var title = Cx.cx([
      "text-black",
      "font-semibold"
    ]);

var sectionContainer = Cx.cx(["col-span-2"]);

var descriptionContainer = Cx.cx(["max-w-[30rem]"]);

var contentContainer = Cx.cx([
      "flex",
      "justify-between",
      "items-center",
      "flex-wrap",
      "gap-3",
      "py-4",
      "lg:px-7",
      "px-5",
      "rounded",
      "border",
      "border-solid",
      "border-gray-200"
    ]);

var textContainer = Cx.cx([
      "flex",
      "flex-col"
    ]);

var titleText = Cx.cx([
      "text-black",
      "text-base",
      "font-semibold"
    ]);

var footerText = Cx.cx([
      "text-sm",
      "text-gray",
      "font-normal"
    ]);

export {
  title ,
  sectionContainer ,
  descriptionContainer ,
  contentContainer ,
  textContainer ,
  titleText ,
  footerText ,
}
/* title Not a pure module */
