// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconDatacenterColocation(props) {
  var color = props.color;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Colocation";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsxs("svg", {
                    children: [
                      JsxRuntime.jsxs("g", {
                            children: [
                              JsxRuntime.jsx("path", {
                                    d: "M15.4667 6.4C15.6081 6.4 15.7438 6.4562 15.8438 6.5562C15.9438 6.65624 16 6.79188 16 6.93332V15.4667C16 15.6081 15.9438 15.7438 15.8438 15.8438C15.7438 15.9438 15.6081 16 15.4667 16H0.533332C0.391884 16 0.256229 15.9438 0.15621 15.8438C0.0561904 15.7438 0 15.6081 0 15.4667V6.93332C0 6.79188 0.0561904 6.65624 0.15621 6.5562C0.256229 6.4562 0.391884 6.4 0.533332 6.4H15.4667Z",
                                    fill: "url(#paint0_linear_4_12)"
                                  }),
                              JsxRuntime.jsx("path", {
                                    d: "M14.0445 0C14.2408 0 14.4 0.1592 14.4 0.355467V5.24452C14.4 5.4408 14.2408 5.6 14.0445 5.6H5.15548C5.0612 5.6 4.97076 5.56256 4.90412 5.49588C4.83744 5.42924 4.8 5.3388 4.8 5.24452V0.355467C4.8 0.1592 4.9592 0 5.15548 0H14.0445ZM12.6221 3.2H6.57788C6.53068 3.2 6.48544 3.21874 6.45208 3.2521C6.41872 3.28545 6.4 3.33069 6.4 3.37787V3.82213C6.4 3.92053 6.47948 4 6.57788 4H12.6221C12.6693 4 12.7146 3.98126 12.7479 3.9479C12.7813 3.91455 12.8 3.86931 12.8 3.82213V3.37787C12.8 3.33069 12.7813 3.28545 12.7479 3.2521C12.7146 3.21874 12.6693 3.2 12.6221 3.2ZM12.6221 1.6H6.57788C6.53068 1.6 6.48544 1.61874 6.45208 1.6521C6.41872 1.68545 6.4 1.73069 6.4 1.77787V2.22213C6.4 2.32053 6.47948 2.4 6.57788 2.4H12.6221C12.6693 2.4 12.7146 2.38126 12.7479 2.3479C12.7813 2.31455 12.8 2.26931 12.8 2.22213V1.77787C12.8 1.73069 12.7813 1.68545 12.7479 1.6521C12.7146 1.61874 12.6693 1.6 12.6221 1.6Z",
                                    fill: "url(#paint1_linear_4_12)"
                                  }),
                              JsxRuntime.jsx("path", {
                                    d: "M13.4221 8.8H12.1779C12.0796 8.8 12 8.87964 12 8.97788V10.2221C12 10.3204 12.0796 10.4 12.1779 10.4H13.4221C13.5204 10.4 13.6 10.3204 13.6 10.2221V8.97788C13.6 8.87964 13.5204 8.8 13.4221 8.8Z",
                                    fill: "white"
                                  }),
                              JsxRuntime.jsx("path", {
                                    d: "M6.22212 8.8H4.97788C4.87964 8.8 4.8 8.87964 4.8 8.97788V10.2221C4.8 10.3204 4.87964 10.4 4.97788 10.4H6.22212C6.32036 10.4 6.4 10.3204 6.4 10.2221V8.97788C6.4 8.87964 6.32036 8.8 6.22212 8.8Z",
                                    fill: "white"
                                  }),
                              JsxRuntime.jsx("path", {
                                    d: "M8.62212 8.8H7.37788C7.27964 8.8 7.2 8.87964 7.2 8.97788V10.2221C7.2 10.3204 7.27964 10.4 7.37788 10.4H8.62212C8.72036 10.4 8.8 10.3204 8.8 10.2221V8.97788C8.8 8.87964 8.72036 8.8 8.62212 8.8Z",
                                    fill: "white"
                                  }),
                              JsxRuntime.jsx("path", {
                                    d: "M11.0221 8.8H9.77788C9.67964 8.8 9.6 8.87964 9.6 8.97788V10.2221C9.6 10.3204 9.67964 10.4 9.77788 10.4H11.0221C11.1204 10.4 11.2 10.3204 11.2 10.2221V8.97788C11.2 8.87964 11.1204 8.8 11.0221 8.8Z",
                                    fill: "white"
                                  }),
                              JsxRuntime.jsx("path", {
                                    d: "M3.82213 8.8H2.57787C2.47963 8.8 2.4 8.87964 2.4 8.97788V10.2221C2.4 10.3204 2.47963 10.4 2.57787 10.4H3.82213C3.92037 10.4 4 10.3204 4 10.2221V8.97788C4 8.87964 3.92037 8.8 3.82213 8.8Z",
                                    fill: "white"
                                  }),
                              JsxRuntime.jsx("path", {
                                    d: "M13.4221 11.2H12.1779C12.0796 11.2 12 11.2796 12 11.3779V12.6221C12 12.7204 12.0796 12.8 12.1779 12.8H13.4221C13.5204 12.8 13.6 12.7204 13.6 12.6221V11.3779C13.6 11.2796 13.5204 11.2 13.4221 11.2Z",
                                    fill: "white"
                                  }),
                              JsxRuntime.jsx("path", {
                                    d: "M11.0221 11.2H9.77788C9.67964 11.2 9.6 11.2796 9.6 11.3779V12.6221C9.6 12.7204 9.67964 12.8 9.77788 12.8H11.0221C11.1204 12.8 11.2 12.7204 11.2 12.6221V11.3779C11.2 11.2796 11.1204 11.2 11.0221 11.2Z",
                                    fill: "white"
                                  }),
                              JsxRuntime.jsx("path", {
                                    d: "M2.57787 11.2H6.22212C6.32052 11.2 6.4 11.2795 6.4 11.3779V14.9333H2.4V11.3779C2.4 11.2795 2.47947 11.2 2.57787 11.2ZM7.37788 11.2H8.62212C8.72052 11.2 8.8 11.2795 8.8 11.3779V14.9333H7.2V11.3779C7.2 11.2795 7.27948 11.2 7.37788 11.2Z",
                                    fill: "white"
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs("defs", {
                            children: [
                              JsxRuntime.jsx("linearGradient", {
                                    children: JsxRuntime.jsx("stop", {
                                          stopColor: Icon.mapColor(color)
                                        }),
                                    id: "paint0_linear_4_12",
                                    gradientUnits: "userSpaceOnUse",
                                    x1: "0",
                                    x2: "1328.94",
                                    y1: "313.6",
                                    y2: "1110.96"
                                  }),
                              JsxRuntime.jsx("linearGradient", {
                                    children: JsxRuntime.jsx("stop", {
                                          offset: "1",
                                          stopColor: Icon.mapColor(color)
                                        }),
                                    id: "paint1_linear_4_12",
                                    gradientUnits: "userSpaceOnUse",
                                    x1: "4.8",
                                    x2: "964.8",
                                    y1: "184.722",
                                    y2: "375.278"
                                  }),
                              JsxRuntime.jsx("clipPath", {
                                    children: JsxRuntime.jsx("rect", {
                                          height: "16",
                                          width: "16",
                                          fill: "white"
                                        }),
                                    id: "clip0_4_12"
                                  })
                            ]
                          })
                    ],
                    height: "16",
                    width: "16",
                    fill: Icon.mapColor(color),
                    viewBox: "0 0 16 16",
                    xmlns: "http://www.w3.org/2000/svg"
                  })
            });
}

var make = IconDatacenterColocation;

export {
  make ,
}
/* Icon Not a pure module */
