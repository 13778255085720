// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var link = Cx.cx([
      "flex",
      "w-full",
      "items-center",
      "justify-between",
      "p-2.5",
      "text-sm",
      "font-medium",
      "hover:text-teal"
    ]);

export {
  link ,
}
/* link Not a pure module */
