// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconChevron from "../../../../icons/IconChevron.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as NavPopoverPanelMainCss from "./NavPopoverPanelMainCss.res.js";

function NavPopoverPanelMain$Container(props) {
  var href = props.href;
  var children = props.children;
  if (href !== undefined) {
    return JsxRuntime.jsx(A.make, {
                href: href,
                className: NavPopoverPanelMainCss.sectionContainer,
                children: children
              });
  } else {
    return JsxRuntime.jsx("section", {
                children: children,
                className: NavPopoverPanelMainCss.sectionContainer
              });
  }
}

var Container = {
  make: NavPopoverPanelMain$Container
};

function NavPopoverPanelMain$IconTextCard(props) {
  var href = props.href;
  return JsxRuntime.jsxs(NavPopoverPanelMain$Container, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: props.icon,
                              className: NavPopoverPanelMainCss.icon
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: props.title,
                                      className: NavPopoverPanelMainCss.titleText
                                    }),
                                JsxRuntime.jsx("span", {
                                      children: props.description,
                                      className: NavPopoverPanelMainCss.textDescription
                                    })
                              ],
                              className: NavPopoverPanelMainCss.textContainer
                            })
                      ],
                      className: NavPopoverPanelMainCss.iconContainer
                    }),
                href !== undefined ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(IconChevron.make, {
                              color: "Gray",
                              direction: "Right"
                            }),
                        className: NavPopoverPanelMainCss.chevronContainer
                      }) : null
              ],
              href: href
            });
}

var IconTextCard = {
  make: NavPopoverPanelMain$IconTextCard
};

function NavPopoverPanelMain$Item(props) {
  var children = props.children;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(NavPopoverPanelMain$IconTextCard, {
                      title: props.title,
                      description: props.description,
                      icon: props.icon,
                      href: props.href
                    }),
                children !== undefined ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx("div", {
                              children: Caml_option.valFromOption(children),
                              className: NavPopoverPanelMainCss.itemContent
                            }),
                        className: NavPopoverPanelMainCss.childrenContainer
                      }) : null
              ],
              className: Cx.cx([NavPopoverPanelMainCss.itemWrapper])
            });
}

var Item = {
  make: NavPopoverPanelMain$Item
};

function NavPopoverPanelMain(props) {
  return JsxRuntime.jsx("section", {
              children: JsxRuntime.jsx("div", {
                    children: props.children,
                    className: NavPopoverPanelMainCss.innerContainer
                  }),
              className: NavPopoverPanelMainCss.sectionContainer
            });
}

var Css;

var make = NavPopoverPanelMain;

export {
  Css ,
  Container ,
  IconTextCard ,
  Item ,
  make ,
}
/* A Not a pure module */
