// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Form from "../../../forms/Form/Form.res.js";
import * as Label from "../../../forms/Label/Label.res.js";
import * as Checkbox from "../../../forms/Checkbox/Checkbox.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as TextField from "../../../forms/TextField/TextField.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ErrorMessage from "../../../forms/ErrorMessage/ErrorMessage.res.js";
import * as ReactTelInput from "../../../../bindings/ReactTelInput.res.js";
import * as TelephoneField from "../../../forms/TelephoneField/TelephoneField.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CheckOutFormScss from "./CheckOutForm.scss";

var css = CheckOutFormScss;

function CheckOutForm(props) {
  var update = props.update;
  var results = props.results;
  var input = props.input;
  var tmp;
  if (results !== undefined) {
    var message = results.firstName;
    tmp = message.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message._0
          });
  } else {
    tmp = null;
  }
  var tmp$1;
  if (results !== undefined) {
    var message$1 = results.lastName;
    tmp$1 = message$1.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$1._0
          });
  } else {
    tmp$1 = null;
  }
  var tmp$2;
  if (results !== undefined) {
    var message$2 = results.company;
    tmp$2 = message$2.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$2._0
          });
  } else {
    tmp$2 = null;
  }
  var tmp$3;
  if (results !== undefined) {
    var message$3 = results.email;
    tmp$3 = message$3.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$3._0
          });
  } else {
    tmp$3 = null;
  }
  var tmp$4;
  if (results !== undefined) {
    var message$4 = results.phoneNumber;
    tmp$4 = message$4.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$4._0
          });
  } else {
    tmp$4 = null;
  }
  var tmp$5;
  if (results !== undefined) {
    var message$5 = results.agreedTermsAndConditions;
    tmp$5 = message$5.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$5._0
          });
  } else {
    tmp$5 = null;
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(Form.make, {
                    className: css.form,
                    onSubmit: props.submit,
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "checkout-form--first-name",
                                            status: Belt_Option.map(results, (function (x) {
                                                    return x.firstName;
                                                  })),
                                            children: "First Name"
                                          }),
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "checkout-form--first-name",
                                            value: input.firstName,
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.firstName.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function ($$event) {
                                                update({
                                                      firstName: $$event.target.value === " " ? "" : $$event.target.value,
                                                      lastName: input.lastName,
                                                      company: input.company,
                                                      email: input.email,
                                                      phoneNumber: input.phoneNumber,
                                                      newsletterSubscription: input.newsletterSubscription,
                                                      agreedTermsAndConditions: input.agreedTermsAndConditions,
                                                      dialCode: input.dialCode
                                                    });
                                              })
                                          }),
                                      tmp
                                    ],
                                    className: Cx.cx([
                                          css.field,
                                          css.field2_md_lg
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "checkout-form--last-name",
                                            status: Belt_Option.map(results, (function (x) {
                                                    return x.lastName;
                                                  })),
                                            children: "Last Name"
                                          }),
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "checkout-form--last-name",
                                            value: input.lastName,
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.lastName.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function ($$event) {
                                                update({
                                                      firstName: input.firstName,
                                                      lastName: $$event.target.value === " " ? "" : $$event.target.value,
                                                      company: input.company,
                                                      email: input.email,
                                                      phoneNumber: input.phoneNumber,
                                                      newsletterSubscription: input.newsletterSubscription,
                                                      agreedTermsAndConditions: input.agreedTermsAndConditions,
                                                      dialCode: input.dialCode
                                                    });
                                              })
                                          }),
                                      tmp$1
                                    ],
                                    className: Cx.cx([
                                          css.field,
                                          css.field2_md_lg
                                        ])
                                  })
                            ],
                            className: css.fieldGroup_md_lg
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(Label.make, {
                                          forId: "checkout-form--company-name",
                                          status: Belt_Option.map(results, (function (x) {
                                                  return x.company;
                                                })),
                                          children: "Company name"
                                        }),
                                    JsxRuntime.jsx(TextField.make, {
                                          id: "checkout-form--company-name",
                                          value: input.company,
                                          status: Belt_Option.map(results, (function (x) {
                                                  if (x.company.TAG === "Ok") {
                                                    return "Valid";
                                                  } else {
                                                    return "Error";
                                                  }
                                                })),
                                          onChange: (function ($$event) {
                                              update({
                                                    firstName: input.firstName,
                                                    lastName: input.lastName,
                                                    company: $$event.target.value === " " ? "" : $$event.target.value,
                                                    email: input.email,
                                                    phoneNumber: input.phoneNumber,
                                                    newsletterSubscription: input.newsletterSubscription,
                                                    agreedTermsAndConditions: input.agreedTermsAndConditions,
                                                    dialCode: input.dialCode
                                                  });
                                            })
                                        }),
                                    tmp$2
                                  ],
                                  className: Cx.cx([
                                        css.field,
                                        css.fieldGroup1_md_lg
                                      ])
                                }),
                            className: css.fieldGroup_md_lg
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "checkout-form--email",
                                            status: Belt_Option.map(results, (function (x) {
                                                    return x.email;
                                                  })),
                                            children: "Email"
                                          }),
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "checkout-form--email",
                                            value: input.email,
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.email.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function ($$event) {
                                                update({
                                                      firstName: input.firstName,
                                                      lastName: input.lastName,
                                                      company: input.company,
                                                      email: $$event.target.value === " " ? "" : $$event.target.value,
                                                      phoneNumber: input.phoneNumber,
                                                      newsletterSubscription: input.newsletterSubscription,
                                                      agreedTermsAndConditions: input.agreedTermsAndConditions,
                                                      dialCode: input.dialCode
                                                    });
                                              })
                                          }),
                                      tmp$3
                                    ],
                                    className: Cx.cx([
                                          css.field,
                                          css.fieldGroup2a_md_lg
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "checkout-form--phoneNumber",
                                            status: Belt_Option.map(results, (function (x) {
                                                    return x.phoneNumber;
                                                  })),
                                            children: "Phone number"
                                          }),
                                      JsxRuntime.jsx(TelephoneField.make, {
                                            id: "checkout-form--phoneNumber",
                                            value: input.phoneNumber,
                                            defaultCountry: "us",
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.phoneNumber.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function (value, selectedCountry) {
                                                var country = ReactTelInput.ReactTelInputData.countryFromJs(selectedCountry);
                                                update({
                                                      firstName: input.firstName,
                                                      lastName: input.lastName,
                                                      company: input.company,
                                                      email: input.email,
                                                      phoneNumber: "+" + (country.dialCode + Js_string.replace("+" + country.dialCode, "", value).trim()),
                                                      newsletterSubscription: input.newsletterSubscription,
                                                      agreedTermsAndConditions: input.agreedTermsAndConditions,
                                                      dialCode: "+" + country.dialCode
                                                    });
                                              }),
                                            preferredCountries: ["us"]
                                          }),
                                      tmp$4
                                    ],
                                    className: Cx.cx([
                                          css.field,
                                          css.fieldGroup2b_md_lg
                                        ])
                                  })
                            ],
                            className: css.fieldGroup_md_lg
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsxs(Checkbox.WithLabel.make, {
                                          id: "checkout-form--agreedTermsAndConditions",
                                          size: "MD",
                                          checked: input.agreedTermsAndConditions,
                                          onChange: (function ($$event) {
                                              update({
                                                    firstName: input.firstName,
                                                    lastName: input.lastName,
                                                    company: input.company,
                                                    email: input.email,
                                                    phoneNumber: input.phoneNumber,
                                                    newsletterSubscription: input.newsletterSubscription,
                                                    agreedTermsAndConditions: $$event.target.checked,
                                                    dialCode: input.dialCode
                                                  });
                                            }),
                                          children: [
                                            "I agree to the ",
                                            JsxRuntime.jsx(A.make, {
                                                  href: "/terms-of-use",
                                                  targetBlank: true,
                                                  children: "Terms & Conditions"
                                                })
                                          ]
                                        }),
                                    tmp$5
                                  ],
                                  className: Cx.cx([
                                        css.field,
                                        css.fieldGroup1_md_lg,
                                        css.fieldMarginBottom
                                      ])
                                }),
                            className: Cx.cx([
                                  css.fieldGroup_md_lg,
                                  css.fistCheckbox
                                ])
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                        id: "checkout-form--newsletterSubscription",
                                        size: "MD",
                                        checked: input.newsletterSubscription,
                                        onChange: (function ($$event) {
                                            update({
                                                  firstName: input.firstName,
                                                  lastName: input.lastName,
                                                  company: input.company,
                                                  email: input.email,
                                                  phoneNumber: input.phoneNumber,
                                                  newsletterSubscription: $$event.target.checked,
                                                  agreedTermsAndConditions: input.agreedTermsAndConditions,
                                                  dialCode: input.dialCode
                                                });
                                          }),
                                        children: "Receive latest industry news"
                                      }),
                                  className: Cx.cx([
                                        css.field,
                                        css.fieldGroup1_md_lg,
                                        css.fieldMarginTop
                                      ])
                                }),
                            className: css.fieldGroup_md_lg
                          }),
                      props.children
                    ]
                  }),
              className: css.formContainer
            });
}

var ReactTelInputData;

var make = CheckOutForm;

export {
  css ,
  ReactTelInputData ,
  make ,
}
/* css Not a pure module */
