// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function edit(id) {
  return "/dashboard/agencies/" + ID.toString(id) + "/edit";
}

function show(id) {
  return "/dashboard/agencies/" + ID.toString(id);
}

function show$1(id) {
  if (id !== undefined) {
    return "/dashboard/tsds/" + ID.toString(Caml_option.valFromOption(id));
  } else {
    return "#";
  }
}

var Tsd = {
  show: show$1
};

var Dashboard = {
  index: "/dashboard/agencies",
  edit: edit,
  $$new: "/dashboard/agencies/new",
  show: show,
  Tsd: Tsd
};

export {
  Dashboard ,
}
/* ID Not a pure module */
