// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconHamburgerMenu(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Group";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              viewBoxSize: "20",
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M1 .5h22a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1zm0 8h22a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1zm0 8h22a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z ",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = IconHamburgerMenu;

export {
  make ,
}
/* Icon Not a pure module */
