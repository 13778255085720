// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var topLinks = Cx.cx([
      "flex",
      "gap-3",
      "lg:gap-5",
      "xl:gap-7",
      "lg:flex-row",
      "flex-col",
      "pl-0",
      "list-none",
      "lg:ml-auto",
      "m-0",
      "mb-0",
      "flex-row",
      "lg:flex-nowrap",
      "flex-wrap",
      "mt-0"
    ]);

var topLinksItem = Cx.cx([
      "flex",
      "items-center",
      "last:mr-0"
    ]);

var signInbutton = Cx.cx([
      "text-white",
      "text-center",
      "text-sm",
      "font-semibold",
      "hover:text-teal"
    ]);

var signUpbutton = Cx.cx([
      "lg:text-white",
      "text-black",
      "font-semibold",
      "text-center",
      "flex-1",
      "text-sm",
      "border",
      "border-white",
      "hover:text-teal",
      "rounded"
    ]);

var signUpbuttonDesktop = Cx.cx([
      signUpbutton,
      "py-2.5",
      "px-5"
    ]);

var signInbuttonMobile = Cx.cx([signInbutton]);

export {
  topLinks ,
  topLinksItem ,
  signInbutton ,
  signUpbutton ,
  signUpbuttonDesktop ,
  signInbuttonMobile ,
}
/* topLinks Not a pure module */
