// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function WithDataProps(props) {
  return React.cloneElement(props.children, props.props);
}

var make = WithDataProps;

export {
  make ,
}
/* react Not a pure module */
