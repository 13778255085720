// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as NavSignedInCss from "./NavSignedInCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function NavSignedIn(props) {
  var setUserData = props.setUserData;
  return JsxRuntime.jsx("a", {
              children: "Logout",
              className: NavSignedInCss.link,
              id: "logout",
              href: "#!",
              onClick: (function (param) {
                  $$Promise.wait(Api.signOut(), (function (x) {
                          if (x.TAG !== "Ok") {
                            return SentryLogger.error1({
                                        rootModule: "NavSignedIn",
                                        subModulePath: /* [] */0,
                                        value: "make",
                                        fullPath: "NavSignedIn.make"
                                      }, "SignOut::Error", [
                                        "Error",
                                        x._0
                                      ]);
                          }
                          setUserData(/* [] */0);
                          Url.visit("/");
                        }));
                })
            });
}

var Css;

var make = NavSignedIn;

export {
  Css ,
  make ,
}
/* Api Not a pure module */
