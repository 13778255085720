import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { BareMetal, Colocation, Multicloud } from '../../../routes/common/Routes_Services.res.js';
import { setUserData } from '../../../../bundles/Client/common/actions/userActions';
import { toggleCart, setCartQty } from '../../../../bundles/Client/common/actions/cartActions';
import DesktopNavigation from './Desktop/DesktopNavigation.res.js';

const routeServicesBareMetal = BareMetal.index;
const routeServicesBareMetalZenlayer = BareMetal.zenlayer;
const routeServicesBareMetalEnzu = BareMetal.enzu;
const routeServicesBareMetalHivelocity = BareMetal.hivelocity;
const routeServicesBareMetalEvocative = BareMetal.evocative;
const routeServicesColocation = Colocation.index;
const routeServicesMulticloud = Multicloud.index;

const mapStateToProps = state => ({
  userData: state.user,
  isUserLoggedIn: !!state.user.id,
  isCartOpen: state.cart.isOpen,
  totalCartQuantity: state.cart.totalCartQuantity,
});

const mapDispatchToProps = dispatch => ({
  setUserData: userData => dispatch(setUserData(userData)),
  toggleCart: () => dispatch(toggleCart()),
  setCartQty: totalCartQuantity => dispatch(setCartQty(totalCartQuantity)),
});

export default ({ context, ...props }) => {
  // Landing page is any page with the big blue jumbotron. Currently, it's homepage, bare metal,
  // colocation and multicloud pages.
  const isLandingPage = [
    routeServicesBareMetalZenlayer,
    routeServicesColocation,
    routeServicesMulticloud,
  ].includes(context.pathname);

  const isTransparent = [
    routeServicesBareMetal,
    routeServicesBareMetalEnzu,
    routeServicesBareMetalHivelocity,
    routeServicesBareMetalEvocative,
  ].includes(context.pathname);

  // Homepage is a special case of a landing page. The difference is that homepage has sticky main
  // navigation, whereas the other pages don't.
  const isHomepage = context.pathname === '/';

  let NavigationContainer = {};

  NavigationContainer = connect(mapStateToProps, mapDispatchToProps)(DesktopNavigation);

  return (
    <ReduxProvider {...props}>
      <NavigationContainer
        {...props}
        {...context}
        isLandingPage={isLandingPage}
        isTransparent={isTransparent}
        isHomepage={isHomepage}
      />
    </ReduxProvider>
  );
};
