// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var outerWrapper = Cx.cx([
      "flex",
      "flex-row",
      "flex-wrap",
      "fixed",
      "top-0",
      "right-0",
      "left-0",
      "!z-1000",
      "w-full"
    ]);

var topNavigation = Cx.cx([
      "flex",
      "flex-row",
      "flex-nowrap",
      "relative",
      "w-full",
      "justify-between",
      "py-3.5",
      "px-7"
    ]);

var generalDialogStyles = Cx.cx([
      "fixed",
      "inset-y-0 right-0 ",
      "w-full ",
      "pb-5",
      "!z-1000",
      "overflow-y-auto ",
      "h-dvh",
      "sm:max-w-sm sm:ring-1",
      "sm:ring-gray-900/10",
      "backdrop-blur-2xl ",
      "duration-300",
      "ease-in-out",
      "ease-out",
      "data-[closed]:transform-[scale(95%)] ",
      "data-[closed]:opacity-0"
    ]);

var diaglogPanel = Cx.cx([
      generalDialogStyles,
      "bg-white"
    ]);

var subDialogPanel = Cx.cx([
      generalDialogStyles,
      "bg-gray-50"
    ]);

var viewAllContainer = Cx.cx([
      "py-5",
      "font-semibold"
    ]);

var linkContainer = Cx.cx([
      "py-5",
      "font-medium"
    ]);

var firstLevelLink = Cx.cx([
      "text-black",
      "text-base",
      "font-medium",
      "leading-5",
      "hover:text-teal"
    ]);

var logoWrapper = Cx.cx([
      "flex",
      "max-w-[200px]",
      "items-center"
    ]);

var sideMenuHeader = Cx.cx([
      "flex",
      "items-center",
      "justify-between",
      "p-6",
      "border-b",
      "border-gray-200",
      "border-solid",
      "bg-white",
      "sticky",
      "top-0"
    ]);

var sectioncCountriesList = Cx.cx([
      "flex",
      "flex-col",
      "gap-4"
    ]);

var countriesList = Cx.cx([
      "flex",
      "flex-col",
      "divide-y",
      "divide-gray-200"
    ]);

var countryText = Cx.cx([
      linkContainer,
      "truncate",
      "w-[120px]",
      "hover:text-teal",
      "font-medium"
    ]);

var noDataContainer = Cx.cx([
      "flex",
      "flex-col",
      "gap-1"
    ]);

var py3 = Cx.cx(["py-3"]);

var px6 = Cx.cx(["px-6"]);

var flexBetween = Cx.cx([
      "flex",
      "items-center",
      "justify-between"
    ]);

var submenuTitle = Cx.cx([
      "text-black",
      "text-lg",
      "font-medium",
      "leading-5",
      "mt-5",
      "pl-4"
    ]);

var subMenuContent = Cx.cx([
      "px-3",
      "flex",
      "flex-col",
      "gap-3"
    ]);

var divider = Cx.cx([
      "divide-y",
      "divide-gray-200"
    ]);

var openMenuIcon = Cx.cx([
      "w-6",
      "h-8"
    ]);

var actionsButtons = Cx.cx([
      "flex",
      "flex-col",
      "gap-3",
      "mt-5"
    ]);

var size6 = Cx.cx(["size-6"]);

var backText = Cx.cx([
      "text-black",
      "text-base",
      "font-medium",
      "leading-5"
    ]);

var listContainer = Cx.cx([
      "flex",
      "flex-col",
      "gap-4"
    ]);

var roleDashboardContainer = Cx.cx([
      "bg-white",
      "rounded-lg",
      "p-5",
      "mt-5",
      "*:py-3",
      divider
    ]);

var groupLinkSubItem = Cx.cx([
      "text-black",
      "text-sm",
      "font-medium",
      "leading-5",
      "hover:text-teal"
    ]);

var cartWrapper = Cx.cx(["gap-1"]);

var cartCounter = Cx.cx([
      "text-orange",
      "text-base"
    ]);

export {
  outerWrapper ,
  topNavigation ,
  generalDialogStyles ,
  diaglogPanel ,
  subDialogPanel ,
  viewAllContainer ,
  linkContainer ,
  firstLevelLink ,
  logoWrapper ,
  sideMenuHeader ,
  sectioncCountriesList ,
  countriesList ,
  countryText ,
  noDataContainer ,
  py3 ,
  px6 ,
  flexBetween ,
  submenuTitle ,
  subMenuContent ,
  divider ,
  openMenuIcon ,
  actionsButtons ,
  size6 ,
  backText ,
  listContainer ,
  roleDashboardContainer ,
  groupLinkSubItem ,
  cartWrapper ,
  cartCounter ,
}
/* outerWrapper Not a pure module */
