// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconProducts(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Products";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M0.66666667,9 L6.33333333,9 C6.70152317,9 7,9.2984768 7,9.6666667 L7,15.3333333 C7,15.7015232 6.70152317,16 6.33333333,16 L0.66666667,16 C0.29847683,16 0,15.7015232 0,15.3333333 L0,9.6666667 C0,9.2984768 0.29847683,9 0.66666667,9 Z M0.66666667,0 L6.33333333,0 C6.70152317,0 7,0.29847683 7,0.66666667 L7,6.33333333 C7,6.70152317 6.70152317,7 6.33333333,7 L0.66666667,7 C0.29847683,7 0,6.70152317 0,6.33333333 L0,0.66666667 C0,0.29847683 0.29847683,0 0.66666667,0 Z M9.6666667,9 L15.3333333,9 C15.7015232,9 16,9.2984768 16,9.6666667 L16,15.3333333 C16,15.7015232 15.7015232,16 15.3333333,16 L9.6666667,16 C9.2984768,16 9,15.7015232 9,15.3333333 L9,9.6666667 C9,9.2984768 9.2984768,9 9.6666667,9 Z M9.6666667,0 L15.3333333,0 C15.7015232,0 16,0.29847683 16,0.66666667 L16,6.33333333 C16,6.70152317 15.7015232,7 15.3333333,7 L9.6666667,7 C9.2984768,7 9,6.70152317 9,6.33333333 L9,0.66666667 C9,0.29847683 9.2984768,0 9.6666667,0 Z",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = IconProducts;

export {
  make ,
}
/* Icon Not a pure module */
