// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var popoverPanelAsideWrapper = Cx.cx([
      "col-span-1",
      "row-span-2",
      "flex",
      "flex-col",
      "gap-2",
      "lg:p-[30px]",
      "p-5"
    ]);

var itemWrapper = Cx.cx([
      "flex",
      "flex-row",
      "items-center",
      "gap-4"
    ]);

var iconContainer = Cx.cx([
      "bg-white",
      "p-2.5",
      "rounded-lg",
      "size-12",
      "shrink-0",
      "flex",
      "justify-center",
      "items-center"
    ]);

var titleText = Cx.cx([
      "text-black",
      "text-base",
      "font-semibold"
    ]);

var titleLink = Cx.cx([
      "text-black",
      "text-sm",
      "font-medium",
      "hover:text-teal"
    ]);

var title = Cx.cx([
      "text-black",
      "font-semibold"
    ]);

export {
  popoverPanelAsideWrapper ,
  itemWrapper ,
  iconContainer ,
  titleText ,
  titleLink ,
  title ,
}
/* popoverPanelAsideWrapper Not a pure module */
