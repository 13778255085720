// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconColocation(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Colocation";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M1,0 L3,0 C3.55228475,0 4,0.44771525 4,1 L4,15 C4,15.5522847 3.55228475,16 3,16 L1,16 C0.44771525,16 0,15.5522847 0,15 L0,1 C0,0.44771525 0.44771525,0 1,0 Z M7,0 L9,0 C9.5522847,0 10,0.44771525 10,1 L10,15 C10,15.5522847 9.5522847,16 9,16 L7,16 C6.44771525,16 6,15.5522847 6,15 L6,1 C6,0.44771525 6.44771525,0 7,0 Z M13,0 L15,0 C15.5522847,0 16,0.44771525 16,1 L16,15 C16,15.5522847 15.5522847,16 15,16 L13,16 C12.4477153,16 12,15.5522847 12,15 L12,1 C12,0.44771525 12.4477153,0 13,0 Z",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = IconColocation;

export {
  make ,
}
/* Icon Not a pure module */
