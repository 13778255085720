// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconCloud(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Colocation";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M15.8253 7.07244L15.005 7.64437L15.1442 7.84402L15.3595 7.95738L15.8253 7.07244ZM5.16601 8.72784L5.49942 9.67062L5.97327 9.50305L6.12152 9.02281L5.16601 8.72784ZM16.6456 6.50051C15.3824 4.6888 13.2801 3.5 10.9 3.5V5.5C12.5986 5.5 14.0998 6.3461 15.005 7.64437L16.6456 6.50051ZM19.5 11.5C19.5 9.19226 18.1968 7.19049 16.291 6.1875L15.3595 7.95738C16.6343 8.62825 17.5 9.96384 17.5 11.5H19.5ZM13.5 17.5C16.8137 17.5 19.5 14.8137 19.5 11.5H17.5C17.5 13.7091 15.7091 15.5 13.5 15.5V17.5ZM10.9 17.5H13.5V15.5H10.9V17.5ZM6.5 17.5H10.9V15.5H6.5V17.5ZM6.5 15.5C4.84315 15.5 3.5 14.1569 3.5 12.5H1.5C1.5 15.2614 3.73858 17.5 6.5 17.5V15.5ZM3.5 12.5C3.5 11.1955 4.33314 10.0831 5.49942 9.67062L4.8326 7.78506C2.89239 8.4712 1.5 10.3217 1.5 12.5H3.5ZM10.9 3.5C7.75221 3.5 5.09214 5.57697 4.21051 8.43287L6.12152 9.02281C6.75171 6.98145 8.65415 5.5 10.9 5.5V3.5Z",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = IconCloud;

export {
  make ,
}
/* Icon Not a pure module */
