// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var disclosureButtonText = Cx.cx([
      "text-black",
      "text-base",
      "font-medium",
      "leading-5"
    ]);

var groupLinkSubItem = Cx.cx([
      "text-black",
      "text-sm",
      "font-normal",
      "leading-5",
      "hover:text-teal"
    ]);

var disclosure = Cx.cx([
      "border-b",
      "border-gray-200",
      "border-solid",
      "[&_svg]:open:-rotate-180"
    ]);

var disclosurePanel = Cx.cx([
      "pt-0",
      "pb-2.5",
      "px-2.5",
      "origin-top",
      "transition",
      "duration-200",
      "ease-out",
      "data-[closed]:-translate-y-6",
      "data-[closed]:opacity-0"
    ]);

var disclosureButton = Cx.cx([
      "group",
      "flex",
      "w-full",
      "items-center",
      "justify-between",
      "p-2.5",
      "outline-none",
      "cursor-pointer"
    ]);

var groupOpenIcon = Cx.cx([
      "cursor-pointer",
      "transition-transform",
      "duration-200",
      "ease-in-out",
      "group-data-[open]:rotate-180",
      "group-data-[open]:text-teal-500"
    ]);

export {
  disclosureButtonText ,
  groupLinkSubItem ,
  disclosure ,
  disclosurePanel ,
  disclosureButton ,
  groupOpenIcon ,
}
/* disclosureButtonText Not a pure module */
