// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var topHeaderWrapper = Cx.cx([
      "w-full",
      "flex",
      "md:flex-row",
      "flex-col",
      "bg-blue-900",
      "md:py-4",
      "md:px-12",
      "p-3"
    ]);

var callAction = Cx.cx([
      "text-white",
      "text-center",
      "flex-1",
      "text-sm",
      "hover:text-white"
    ]);

var numberText = Cx.cx(["underline"]);

var cartWrapper = Cx.cx([
      "lg:flex",
      "hidden",
      "text-gray",
      "text-sm",
      "gap-1"
    ]);

var cartCounter = Cx.cx([
      "text-orange",
      "text-sm"
    ]);

export {
  topHeaderWrapper ,
  callAction ,
  numberText ,
  cartWrapper ,
  cartCounter ,
}
/* topHeaderWrapper Not a pure module */
